// Customizable Area Start
import React from "react";
import {
    Box,
    Grid,
    Typography,
    styled,
    Divider,
    Button,
    Dialog,
    OutlinedInput,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton
} from "@material-ui/core"
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import ProfileSidebar from "../../../components/src/ProfileSidebar.web";
import ProfileHeader from "../../../components/src/ProfileHeader.web";
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import TicketSupportController, {
    Props,
  } from "./TicketSupportController";
import { ticketIcon } from "./assets";


class TicketSupport extends TicketSupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
        <div>
            <AppHeader navigation={this.props.navigation} />

            <TicketSupportWrapper>

                <ProfileHeader />

                <Grid container spacing={3}>
                    <Grid item className="mainGrid">
                        <Box style={{ margin: "30px 0" }} className="mainBoxStyle">
                            <ProfileSidebar activeKey={5} navigation={this.props.navigation} />
                        </Box>
                    </Grid>
                    <Grid item md={9} className="mainBoxSecondary">
                        <Box style={{ margin: "30px 0" }}>
                            <Box className="paddingBox">
                                <Box className="breadcrumbsBox">
                                    <Typography className="breadcrumbText" onClick={this.navigateToSupportCenter} data-test-id="breadcrumbText">Support Center</Typography>
                                    <ArrowForwardIosIcon style={{ height: 14, color: "#64748B" }} fontSize="small" />
                                    <Typography className="breadcrumbText"><span className="colorText">Ticket Support</span></Typography>
                                </Box>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Box>
                                        <Typography className="mainText">Ticket Support</Typography>
                                        <Typography className="subText">We're here to help! Our support agents are available 24/7 to assist you!</Typography>
                                    </Box>
                                    <Button
                                        className="createBtn"
                                        startIcon={<AddIcon style={{ color: "white" }} />}
                                        data-test-id="createBtn"
                                        onClick={this.handleOpenTicketModal}
                                    >
                                        Create a new ticket
                                    </Button>
                                </Box>
                               
                                <Divider style={{ margin: "20px 0" }} />

                                <Typography className="ticketText">Available Tickets</Typography>

                                {[0, 1].map(itemValue =>
                                    <Box className="mainTicketBox" key={itemValue}>
                                        <Box className="innerBox1">
                                            <Box style={{ display: "flex", gap: 8 }}>
                                                <div>
                                                    <img src={ticketIcon} />
                                                </div>
                                                <div>
                                                    <Typography className="ticketMessage">The host added charge after completing reservation.</Typography>
                                                    <Typography className="catogoryText">Catogory : <span className="catogoryType"> Rental Issue</span></Typography>
                                                </div>
                                            </Box>
                                            <Box className="currentOpen">
                                                Currently open
                                            </Box>
                                        </Box>

                                        <Box className="innerBox1">
                                            <Typography className="ticketSubMessage">The host tried charging me extra for miles I did not go over on my Ferrari 488 Rental ...</Typography>
                                            <Typography className="ticketSubMessage">Today at 9:30am</Typography>
                                        </Box>
                                    </Box>
                                )}      

                                <Typography className="ticketText">Previous History</Typography>

                                {[0, 1,2].map(itemValue =>
                                    <Box className="mainTicketBox" key={itemValue}>
                                        <Box className="innerBox1">
                                            <Box style={{ display: "flex", gap: 8 }}>
                                                <div>
                                                    <img src={ticketIcon} />
                                                </div>
                                                <div>
                                                    <Typography className="ticketMessage">The host added charge after completing reservation.</Typography>
                                                    <Typography className="catogoryText">Catogory : <span className="catogoryType"> Rental Issue</span></Typography>
                                                </div>
                                            </Box>
                                            <Box className="resolved">
                                                Resolved
                                            </Box>
                                        </Box>

                                        <Box className="innerBox1">
                                            <Typography className="ticketSubMessage">The host tried charging me extra for miles I did not go over on my Ferrari 488 Rental ...</Typography>
                                            <Typography className="ticketSubMessage">Today at 9:30am</Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </TicketSupportWrapper>

            <DialogWrapper
                open={this.state.createTicketModal}
                onClose={this.handleCloseTicketModal}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle style={{ borderBottom: "1px solid #EAEAEA", padding: "8px 24px" }}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography className='headerText'>Create a New Ticket</Typography>
                        <IconButton edge="end" color="inherit" data-test-id="closeBtn" onClick={this.handleCloseTicketModal}>
                            <HighlightOffOutlinedIcon style={{ color: "#475467" }} />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent>

                    <Box className="inputBox">
                        <Typography className="textLabel">Subject Title*</Typography>
                        <OutlinedInput
                            className="textField"
                            name="title"
                            placeholder="Title"
                            fullWidth
                        />
                    </Box>

                    <Box className="inputBox">
                        <Typography className="textLabel">Catogory*</Typography>
                        <OutlinedInput
                            className="textField"
                            name="catogory"
                            placeholder="Catogory"
                            endAdornment={<ExpandMoreIcon style={{ color : "#667085"}} />}
                            fullWidth
                        />
                    </Box>

                    <Box className="inputBox">
                        <Typography className="textLabel">Reference a Past Rentel</Typography>
                        <OutlinedInput
                            className="textField"
                            name="reference"
                            placeholder="Reference"
                            endAdornment={<ExpandMoreIcon style={{ color : "#667085"}} />}
                            fullWidth
                        />
                    </Box>

                    <Box className="inputBox">
                        <Typography className="textLabel">Message*</Typography>
                        <OutlinedInput
                            className="textAreaField"
                            name="message"
                            placeholder="Type here"
                            fullWidth
                        />
                    </Box>
                    
                    
                </DialogContent>

                <DialogActions style={{ borderTop: "1px solid #EAEAEA"}}>
                    <Box className="gridBox">
                        <Button data-test-id="cancelBtn" className="cancelBtn" onClick={this.handleCloseTicketModal}>Cancel</Button>
                        <Button data-test-id="saveBtn" className="saveBtn" onClick={this.handleCloseTicketModal}>Create</Button>
                    </Box>
                </DialogActions>
            </DialogWrapper>

            <Footer />
        </div>
    );
    // Customizable Area End
  }
}

export default TicketSupport;

const TicketSupportWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    "& .mainBox": {
        "@media(max-width:960px)": {
            display: "flex",
        }
    },
    "& .mainGrid": {
        "@media(max-width:960px)": {
            width: "100% !important",
            overflowX: "scroll",
        }
    },
    "& .mainBoxStyle": {
        "@media(max-width:960px)": {
            display: "flex",
            "& .MuiGrid": {
                maxWidth: "100% !important"
            }
        }
    },
    "& .mainBoxSecondary": {
        width: "100% !important"
    },
    "& .mainText": {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 600,
        color: "#101828",
        fontSize: "18px",
        marginBottom: "4px",
    },
    "& .colorText" : {
        color: "#3173E1",
      },
    "& .mainTicketBox" : {
        padding: '20px',
        border: '1px solid #EAECF0',
        borderRadius: '12px',
        margin:"15px 0"
    },
    "& .innerBox1" : {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0"
    }, 
    "& .ticketText": {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 500,
        color: "#101828",
        fontSize: "16px",
    },
    "& .ticketMessage" : {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 600,
        fontSize: "16px",
        color: "#101828",
    },
    "& .catogoryText" : {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 500,
        fontSize: "14px",
        color: "#344054",
    },
    "& .catogoryType" : {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 400,
        fontSize: "14px",
        color: "#667085",
    },
    "& .ticketSubMessage" : {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 400,
        fontSize: "14px",
        color: "#475467",
    },
    "& .currentOpen" : {
        height: "20px",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '14px',
        color: "#3173E1",
        borderRadius: 16,
        padding: "2px 10px",
        border: "1px solid #3173E1"
    },
    "& .resolved" : {
        height: "20px",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '14px',
        color: "#067647",
        borderRadius: 16,
        padding: "2px 10px",
        background: "#ECFDF3",
        border: "1px solid #ABEFC6"
    },
    "& .createBtn" : {
        height: "40px",
        width: "190px",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        textTransform: "none",
        fontSize: '14px',
        color: "white",
        borderRadius: 30,
        background: "#3173E1"
    },
    "& .paddingBox": {
        padding: "0 30px",
    },
    "& .subText": {
        fontFamily: "Plus Jakarta Sans",
        color: "#475467",
        fontSize: "16px",
        fontWeight: 400,
    },
    "& .breadcrumbsBox": {
        display: "flex",
        gap: "10px",
        marginBottom: "30px",
        alignItems: "center"
    },
    "& .breadcrumbText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        textAlign: 'left',
        fontWeight: 600,
        color: '#64748B',
        cursor: "pointer"
    },
      
      
})

const DialogWrapper = styled(Dialog)({
    "& .MuiPaper-rounded" : {
        borderRadius : "16px !important",
    },
    "& .headerText" : {
        fontWeight: 600,
        fontFamily: "Plus Jakarta Sans",
        color: "#101828",
        fontSize: "18px",
    },
    "& .cancelBtn" : {
        height: "40px",
        fontSize: '14px',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        width: "80px",
        border: "1px solid #D0D5DD",
        borderRadius: 30,
        textTransform: "none",
        color: "#475467",
    },
    "& .saveBtn" : {
        height: "40px",
        width: "130px",
        fontWeight: 600,
        textTransform: "none",
        fontFamily: 'Plus Jakarta Sans',
        borderRadius: 30,
        color: "white",
        fontSize: '14px',
        background: "#3173E1",
    },
    "& .gridBox" : {
        display: "flex",
        gap: "15px"
    },
    "& .inputBox" : {
        margin: "10px 0"
     },
    "& .textLabel": {
        fontWeight: 500,
        fontSize: "14px",
        color: "#667085",
        fontFamily: "Plus Jakarta Sans",
        marginBottom: "5px"
    },
    "& .textField": {
        borderRadius: "30px",
        height: "40px",
        padding: "10px 14px",
    },
    "&  .textField .MuiOutlinedInput-input": {
        padding: 0
    },
    "& .textAreaField": {
        borderRadius: "14px",
        height: "120px",
        display: "block",
        padding: "10px 14px",
    },
    "&  .textAreaField .MuiOutlinedInput-input": {
        padding: 0
    },
})

// Customizable Area End