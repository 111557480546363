// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  renderSection : string
  referLink : string
  tooltipText : string,
  ratingValue : number,
  ratingDescription : string,
  bookSlotId :string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReviewScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getReferCodeApiCallId: string = ""
  saveReviewApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        renderSection : this.allSection.reviewSection,
        referLink : "",
        tooltipText : "Copy Link",
        ratingValue : 0,
        ratingDescription : "",
        bookSlotId : ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
         case this.getReferCodeApiCallId :
            return this.handleReferCodeResponse(responseJson)
         case this.saveReviewApiCallId : 
            return this.handleReviewResponse(responseJson)
         default :
            break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      const bookedSlotId = this.props.navigation.getParam("bookSlotId")
      this.setState({ bookSlotId: bookedSlotId })
      this.getReferCodeData()
  }

  getReferCodeData = () => {
      const header = {
          'token': localStorage.getItem("userToken")
      }

      this.getReferCodeApiCallId = apiCalling({
          header: JSON.stringify(header),
          endPoint:configJSON.getReferalApiEndPoint,
          method: configJSON.apiTypeGet
      })
  }

  saveReview = () => {
    if(this.state.ratingValue === 0 || this.state.ratingDescription === "") {
       return toast.error("Please fill required detail")
    }

    const header = {
        'token': localStorage.getItem("userToken"),
        "Content-Type": "application/json",
    }

    const httpBody = {
        "review": {
            "booked_slot": this.state.bookSlotId,
            "rating": this.state.ratingValue,
            "comment": this.state.ratingDescription
        }
    }
    

    this.saveReviewApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint:configJSON.saveReviewsApiEndPoint,
        method: configJSON.apiTypePost,
        body : JSON.stringify(httpBody)
    })

  }

  handleReviewResponse = (responseJson : any) => {
    if(responseJson && responseJson.data) {
        toast.success("Review Added Succeffully")
        this.setState({ renderSection : this.allSection.referSection})
    } else {
        toast.error("Something went wrong. Please Try again later")
     }
  }

  handleReferCodeResponse = (responseJson : any) => {
    if(responseJson && responseJson.link) {
        const referLink = truthyValue(responseJson.link)
        this.setState({ referLink })
    }
  }

  allSection = {
    reviewSection : "review",
    referSection : "refer"
  }

  navToReservationPage = () => {
    this.props.navigation.navigate("Reservation")
  }

  handleRatingValue = (event: React.ChangeEvent<{}>, value: number | null) => {
     if(value) {
         this.setState({ ratingValue : value})
     }
  }

  handleChangeForRatingDescription = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ ratingDescription : event.target.value})
  }


  copyUrl = () => {
    const input = this.state.referLink
    if (input !== "") {
      navigator.clipboard.writeText(input)
        .then(() => {
            this.setState({ tooltipText : "Link Copied"})
        })
        .catch(err => {
            this.setState({ tooltipText : "Unable to Copy link"})
        });
    }
  }


  // Customizable Area End
}

// Customizable Area End