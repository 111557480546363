// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface UserData {
    fullName : string,
    joined: string,
    profileImg : string
}

interface S {
  // Customizable Area Start  
  loading : boolean,
  verificationStatus : string,
  verificationSteps : number,
  userData : UserData,
  licenceDoc : File | null,
  selfieDoc : File | null,
  reUploadedDoc : File | null,
  isDisplayed : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ApprovalStatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  licenceImgRef: React.RefObject<HTMLInputElement>
  selfieImgRef: React.RefObject<HTMLInputElement>
  reUploadLicenceRef: React.RefObject<HTMLInputElement>
  getUserDetaApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      loading: true,
      verificationStatus: this.allVerificationStatus.not_verified,
      verificationSteps: 1,
      userData : {
        fullName : "",
        joined: "",
        profileImg: ""
      },
      selfieDoc : null,
      licenceDoc : null,
      reUploadedDoc: null,
      isDisplayed : true
    };
    this.licenceImgRef = React.createRef()
    this.selfieImgRef = React.createRef()
    this.reUploadLicenceRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getUserDetaApiCallId) {
         return this.handleUserDataResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount(): Promise<void> {
      this.getUserData()
  }

  getUserData = () => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getUserDetaApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.getUserDetailsApiEndPoint,
        method: configJSON.apiMethodTypeGet
    })
  }

  handleUserDataResponse = (responseJson: any) => {
    if(responseJson && responseJson.user) {
        const { data } = responseJson.user
         const userData = {
            fullName: truthyValue(data.attributes.full_name),
            joined: truthyValue(data.attributes.joined),
            profileImg: truthyValue(data.attributes.profile_image_url.url),
         }

         this.setState({ userData : userData, loading: false})
      } else {
          this.setState({ loading: false})
      }
   }

   allVerificationStatus = {
     not_verified : "Unverified",
     verified : "Verified",
     in_progress : "Inprogress",
     need_review : "Needs Review",
     denied : "Denied"
   }

   handleChipCSS = (value : string) => {
       switch (value) {
           case this.allVerificationStatus.not_verified:
               return "unVerifiedChip"
           case this.allVerificationStatus.in_progress:
               return "progressChip"
           case this.allVerificationStatus.verified:
               return "verifiedChip"
           case this.allVerificationStatus.denied:
               return "deniedChip"
           case this.allVerificationStatus.need_review:
               return "progressChip"
       }
   }

    handleChipNoteText = (value: string) => {
        switch (value) {
            case this.allVerificationStatus.not_verified:
                return "Verification required for rentals"
            case this.allVerificationStatus.in_progress:
                return ""
            case this.allVerificationStatus.verified:
                return ""
            case this.allVerificationStatus.denied:
                return "Driver License Expired"
            case this.allVerificationStatus.need_review:
                return "Driver License Expired"
        }
    }

    goToStep2 = () => {
        if(this.state.licenceDoc === null || this.state.selfieDoc == null) {
            toast.error("Please upload both documents to continue")
            return false
        }
        this.setState({ verificationSteps : 2, verificationStatus : this.allVerificationStatus.in_progress})
    }

    goToStep3 = () => {
        this.setState({ verificationSteps : 3, verificationStatus : this.allVerificationStatus.verified})
    }

    goToStep4 = () => {
        this.setState({ verificationSteps : 4, verificationStatus : this.allVerificationStatus.need_review})
    }

    goToStep5 = () => {
        this.setState({ verificationSteps : 5, verificationStatus : this.allVerificationStatus.denied})
    }

    handleLicenceRefCall = () => {
        if (this.licenceImgRef.current) {
            this.licenceImgRef.current.click();
        }
    }

    handleLicenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            this.setState({ licenceDoc: event.target.files[0] });
        }
    };

    handleSelfieRefCall = () => {
        if (this.selfieImgRef.current) {
            this.selfieImgRef.current.click();
        }
    }

    handleSelfieChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            this.setState({ selfieDoc: event.target.files[0] });
        }
    };

    handleReUploadDocumentRefCall = () => {
      if (this.reUploadLicenceRef.current) {
          this.reUploadLicenceRef.current.click();
      }
  }

  handleReUploadDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
          this.setState({ reUploadedDoc: event.target.files[0]}, () => this.goToStep4());
      }
  };

  clearAllFields = () => {
    this.setState({
       licenceDoc : null,
       selfieDoc: null,
       reUploadedDoc: null,
       verificationSteps : 2,
       verificationStatus : this.allVerificationStatus.in_progress,
       isDisplayed : false
    })
  }

  // Customizable Area End
}

// Customizable Area End