import React from "react";
// Customizable Area Start
import { Box, Typography, Card, CardActionArea, CardActions, styled,Button, Radio, Select, MenuItem, OutlinedInput, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { HeartIcon, ArrowLeft, ArrowRight, BedIcon, ShowerIcon, LocationIcon, cardViewIcon, mapViewIcon, filterIcon, sortIcon, dropDown, SeatOption, FuelIcon, AddDates, Location, Guest, CarTab, HomeTab, Search, plusMinusIcon } from "./assets"
import Pagination from '@material-ui/lab/Pagination';
import Loader from "../../../components/src/Loader.web";
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import Tabs from "../../../components/src/Tabs.web";
import SimpleImageSlider from "react-simple-image-slider";
import { truthyBedValueValue, truthyToiletValueValue, SearchCriteria, starIcon} from "../../../components/src/asset"
import { DateRange } from 'react-date-range';
import moment from "moment"
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
// import { Map, GoogleApiWrapper, Marker, Polyline, InfoWindow } from "google-maps-react";

interface Location {
  attributes: {
    address: {
      latitude: number;
      longitude: number;
      city: string;
      country: string;
    };
  };
}

const CustomCardActions = styled(CardActions)({
  alignItems: "center", display: "flex", justifyContent: "space-between", padding: '8px 16px 16px', gap: 12,
  '& p': {
    margin: 0
  }
})
const TabBox = styled(Box)({
  maxWidth: '263px',
  width: '100%',
  borderRadius: 30,
  background: 'white',
  display: 'flex',
  border: '1px solid #D0D5DD',
  '& .first-child': {
    padding: '8px 15px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#475467',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    width: 'max-content',
    cursor:"pointer"
  },
  '& .side-border': {
    borderRight: '1px solid #D0D5DD'
  },
  '& .first-child.activeCardView': {
    cursor:"pointer",
    backgroundColor: '#F2F4F7',
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    color: '#22395D'
  },
})
const CustomPagination = styled(Pagination)({
  '& .MuiPagination-ul li:first-child': {
    display: 'none',
  },
  '& .MuiPagination-ul li:last-child': {
    display: 'none',
  },
  '@media(max-width: 767px)': {
    '& .MuiPaginationItem-root': {
      minWidth: 25, height: 25, margin: 0
    }
  }
})

const FilterParentBox = styled(Box)({
  '@media(max-width: 1260px)': {
    paddingInline: 20,
    width: '96% !important'
  },
  '@media(max-width: 625px)': {
    paddingInline: 10,
  },
  '@media(max-width: 960px)': {
    marginTop: '14px !important',
    '& .mobile-hide': {
      display: 'none !important'
    }
  }
})
const CustomTabs = styled(Box)({
  display: 'none',
  '@media(max-width: 960px)': {
    display: 'block !important'
  },
})
const ShowPageBox = styled(Box)({
  '@media(max-width: 767px)': {
    display: 'none !important'
  },
})
const NextPreBox = styled(Box)({
  '@media(max-width: 767px)': {
    '& p': {
      display: 'none !important'
    }
  },
})
const CardInnerBox = styled(Box)({
  '@media(max-width: 1260px)': {
    paddingInline: 20,
    width: '96% !important'
  },
  '@media(max-width: 625px)': {
    paddingInline: 10,
  },
})
const HeaderBox = styled(Box)({
  background: 'linear-gradient(267.4deg, #7A36BA 7.12%, #3173E1 103.5%)',
  width: '100%',
  padding: '24px 0px 45px',
  '@media(max-width: 1260px)': {
    paddingInline: 20,
    width: 'unset',
  },
  '@media(max-width: 960px)': {
    display: 'none'
  },
  '& .innerHeaderBox': {
    maxWidth: 1220,
    margin: 'auto',
    '@media(max-width: 1260px)': {
      width: 'fit-content',
    },
  },
  '& .header-text': {
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '38px',
    textAlign: 'left',
    color: 'white',
    marginBottom: 24
  },
  '& .tab-box': {
    maxWidth: '200px',
    width: '100%',
    borderRadius: 30,
    background: 'white',
    display: 'flex',
    marginBottom: 8,
    overflow: 'hidden'
  },
  '& .first-child': {
    cursor:"pointer",
    flex: 1,
    padding: 10,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#475467',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8
  },
  '& .first-child.active': {
    cursor:"pointer",
    backgroundColor: '#F2F4F7',
    color: '#22395D'
  },
  '& .side-border': {
    borderRight: '1px solid #D0D5DD'
  },
  '& .filter-box': {
    width: '100%',
    height: 78,
    borderRadius: 50,
    display: 'flex',
    '& input': {
      border: 'none',
      fontSize: 16,
      color: 'black',
      paddingLeft: 20,
      outline: 'none',
      background: "transparent"
    },
    '& label': {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
      textAlign: 'left'
    },
    '& input::placeholder': {
      color: '#667085'
    },
    '& input:focus-visible': {
      outline: 'none'
    }
  },
  '& .input-box2': {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    width: '100%',
    maxWidth: '384px',
    background: 'white',
    justifyContent: 'center',
    padding: '16px 24px',
    borderRight: '1px solid #EAECF0',
    position: 'relative',
    borderRadius: '50px 0px 0px 50px',
    "&:hover": {
      background: "#F2F4F7",
      // borderRadius: 50
    },
    '@media(max-width: 1260px)': {
      maxWidth: '200px'
    }
  },
  '& .input-box': {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    gap: 4,
    width: '100%',
    maxWidth: '170px',
    justifyContent: 'center',
    padding: '16px 24px',
    borderRight: '1px solid #EAECF0',
    position: 'relative',
    "&:hover": {
      background: "#F2F4F7",
      // borderRadius: 50
    },
    '@media(max-width: 1260px)': {
      maxWidth: '120px',
    }
  },
  '& .icons': {
    position: 'absolute',
    left: 20,
    bottom: 17
  },
  '& .search-box': {
    background: '#3173E1',
    maxWidth: 188,
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    borderRadius: '0px 50px 50px 0px',
    cursor: "pointer",
    '@media(max-width: 1260px)': {
      minWidth: '120px',
      maxWidth: '120px',
    }
  },
  "& .emptyBox" : {
    position: "absolute",
    top: "100%",
    zIndex: 115,
    width: "max-content",
    background: "white",
    boxShadow: "0px 4px 5px 0px #0000000D",
    borderRadius: 30

  },
  "& .optionContentText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#475467'
  },
  "& .timeTextBox" : {
    width: 170, 
    height: 45,
    borderRadius: "30px",
    border : "1px solid #CBD5E1",
    marginTop: 10,
  }, 
  "& .timeTextBox .MuiSelect-icon ": {
    right: 15
  },
  "& .disabledText .MuiOutlinedInput-input" : {
    color: "#98A2B3",
  },
  "& .fieldBox" : {
    margin: "15px"
  }, 
  "& .dateRangePicker" : {
    borderBottom: "1px solid #EAECF0",
    borderRight: "1px solid #EAECF0",
  }
});

const SliderParentBox = styled(Box)({
  '& button': {
    width: '5px !important',
    height: '5px !important',
    border: 'none !important',
    background: 'lightgray !important',
  }
})
import FilterModal from "../../../components/src/FilterModal.web";

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getWebList(item: any) {
    let value = item.attributes;
    return (
      <Box sx={webStyle.productBox} key={`product-item-${item.id}`}>
        <Box sx={webStyle.ImgContainer}>
          <img style={webStyle.productImg} src={value.images[0].url} />
        </Box>
        <Box sx={webStyle.detailContent}>
          <Typography variant="h6">{value.name}</Typography>
          <Typography variant="h6">{value.price}</Typography>
          <Typography variant="h6">{value.average_rating}</Typography>
        </Box>
      </Box>
    );
  };

  AppHeader=()=>{
    return  <Box>
    <AppHeader activeLink="" navigation={this.props.navigation}/>
    <HeaderBox>
      <Box className='innerHeaderBox'>
        <Typography className='header-text'>{this.state.listingName==="Home"?"Find your next stay":"Find your next ride"}</Typography>
        <Box className='tab-box'>
          <Box data-test-id="testTeb" className={this.state.listingName==="Home"?'first-child side-border active':'first-child side-border'} 
          onClick={()=>this.handleTab("Home")}
          >
            <img className='home-icon' src={HomeTab} alt="" />
            Home</Box>
          <Box data-test-id="testTeb1" className={this.state.listingName==="Car"?'first-child active':'first-child'} 
          onClick={()=>this.handleTab("Car")}
          ><img className='car-icon' src={CarTab} alt="" />Car</Box>
        </Box>
        <Box className='filter-box'>
          <Box className='input-box2'  data-test-id="HandleFilterTest" onClick={this.handleOpenFilter}>
            {this.state.listingName==="Home"?<label htmlFor="">Where</label>:<label htmlFor="">Pick-up Location</label>}
            <img className='icons' src={Location} alt="" />
              <input type="text" value={this.state.searchResult} data-test-id="handleSearchTest"  onChange={(event) => this.handleSearch(event.target.value)}  placeholder='Search destination' />
            {this.state.openFilter === true && 
             <Box style={{ cursor: "pointer", position: 'absolute', top: 80, zIndex: 9999, maxWidth: '340px', width: '100%',objectFit:"contain",left: '0px', background: 'white', borderRadius: 16, border: '1px solid #F2F4F7', boxShadow: '0px 8px 8px -4px #10182808', padding: '4px 20px', maxHeight: '216px', overflow: 'auto' }}>
              {this.state.filteredCities.map((data:any)=><Typography onClick={()=>this.handleSearchDataList(data)} style={{ padding: '16px 0px', borderBottom: '1px solid #EAECF0', color: '#23395D', display: 'flex', alignItems: 'center' }}><img src={Location} style={{ marginRight: 8 }} />{data}</Typography>)}
            </Box>}
          </Box>

                       
          <Box className='input-box' >
            {this.state.listingName==="Home"?<label htmlFor="">Check In</label>:<label htmlFor="">Pick-up Date</label>}
            <img className='icons' src={AddDates} alt="" />
           {this.state.listingName==="Home"?<input data-test-id="openHomeDatePicker" onClick={this.toggleDateRangePicker} type="text" value={moment(this.state.selectedDateOnAddDates[0].startDate).format('MMMM DD YYYY')} placeholder='Add dates' />:<input data-test-id="openCarDatePicker" onClick={this.toggleDateRangePicker} value={moment(this.state.selectedDateOnAddDates[0].startDate).format('MMMM DD YYYY')} type="text" placeholder='Add dates' />}

              {this.state.openDateRangePicker ?
                <Box className="emptyBox">

                  <Box style={{ display: "flex", gap: "10px" }}>
                    <DateRange
                      editableDateInputs={false}
                      onChange={(item) => this.setState({ selectedDateOnAddDates: [item.selection] })}
                      ranges={this.state.selectedDateOnAddDates}
                      className="dateRangePicker"
                      months={2}
                      direction="horizontal"
                      data-test-id="dateRangePicker"
                    />

                    <Box>
                      <Box className="fieldBox">
                        <Typography className="optionContentText">{this.handleCheckInTextLabel()}</Typography>
                        <OutlinedInput className="timeTextBox disabledText" value={moment(this.state.selectedDateOnAddDates[0].startDate).format('MMM Do, YYYY')} readOnly />
                      </Box>

                      <Box className="fieldBox">
                        <Typography className="optionContentText">Time</Typography>
                        <Select data-test-id="checkInTime" style={{ padding : "0 15px"}} value={this.state.checkInTime} onChange={this.handleCheckInChange} displayEmpty  className="timeTextBox" disableUnderline>
                        <MenuItem value="" disabled>Choose Time</MenuItem>
                          {this.generateTimeArray().map(item =>
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                          )}
                        </Select>
                      </Box>

                      <Box className="fieldBox">
                        <Typography className="optionContentText">{this.handleCheckOutTextLabel()}</Typography>
                        <OutlinedInput className="timeTextBox disabledText" value={moment(this.state.selectedDateOnAddDates[0].endDate).format('MMM Do, YYYY')} readOnly ></OutlinedInput>
                      </Box>

                      <Box className="fieldBox">
                        <Typography className="optionContentText">Time</Typography>
                        <Select data-test-id="checkOutTime" style={{ padding : "0 15px"}} value={this.state.checkOutTime} onChange={this.handleCheckOutChange} className="timeTextBox" displayEmpty disableUnderline>
                          <MenuItem value="" disabled>Choose Time</MenuItem>
                          {this.generateTimeArray().map(item =>
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                          )}
                        </Select>
                      </Box>
                    </Box>

                  </Box>


                  <Box style={{ width: "76.8%", borderRight: "1px solid #EAECF0" }}>
                    <Box style={{ display: "flex" }}>
                      {this.definedRanges.map(item =>
                        <Box style={{ display: "flex", alignItems: "center", margin: "20px 15px" }}>
                            
                          <Radio checked={item.id === this.state.selectedItem} name="dateOption" value={item.addDays} onChange={(event) => this.onOptionClicks(event, item.id)} color="primary" />
                          <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            {item.id !== 1 && <img src={plusMinusIcon} alt="pm" />}
                            <Typography className="optionContentText">{item.label}</Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>

                </Box>
              : null}
          </Box>


          <Box className='input-box'>
           {this.state.listingName==="Home"?<Box><label htmlFor="">Check Out</label>
            <img className='icons' src={AddDates} alt="" />
            <input type="text" value={moment(this.state.selectedDateOnAddDates[0].endDate).format('MMMM DD YYYY')} placeholder='Add dates' /></Box>:<Box onClick={this.handleOpenDropFilter}><label htmlFor="">Drop-off Location</label>
            <img className='icons' src={Guest} alt="" />
            <input type="text" data-test-id="HandleDropsearch" value={this.state.searchDropResult} onChange={(event) => this.handleDropSearch(event.target.value)} placeholder='Search destination' />
            {this.state.openDropFilter === true && <Box style={{ position: 'absolute', top: 80, zIndex: 9999, maxWidth: '340px', width: '100%',objectFit:"contain",left: '0px', background: 'white', borderRadius: 16, border: '1px solid #F2F4F7', boxShadow: '0px 8px 8px -4px #10182808', padding: '4px 20px', maxHeight: '216px', overflow: 'auto' }}>
              {this.state.filteredCities.map((data:any)=><Typography onClick={()=>this.handleSearchDataForDropLocationList(data)} style={{ padding: '16px 0px', borderBottom: '1px solid #EAECF0', color: '#23395D', display: 'flex', alignItems: 'center' }}><img src={Location} style={{ marginRight: 8 }} />{data}</Typography>)}
            </Box>}
            </Box>} 
          </Box>
          <Box className='input-box' >
            <Box>
            {this.state.listingName==="Home"?<Box data-test-id="testGuestModal" onClick={this.handleGuestModal}><label  htmlFor="">Guests</label>
            <img className='icons' src={Guest} alt="" />
            <input type="text" value={this.state.guestCount} placeholder='Add Guests' />
            </Box>:<Box><label htmlFor="">Drop-off Date</label>
            <img className='icons' src={AddDates} alt="" />
            <input type="text" value={moment(this.state.selectedDateOnAddDates[0].endDate).format('MMMM DD YYYY')} placeholder='Add date & time' /></Box>}
            </Box>
            {this.state.listingName==="Home" && this.state.addGuest && <Box style={{ position: 'absolute', top: 80, zIndex: 9999, width: '260px', left: '0px', background: 'white', borderRadius: 16, border: '1px solid #F2F4F7', boxShadow: '0px 8px 8px -4px #10182808' }}>
              <Box style={{ padding: '20px' }}>
                <Typography style={{ padding: '16px 0px', borderBottom: '1px solid #EAECF0', color: '#23395D', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                  <Box>
                    <Typography style={{ fontSize: 14, fontWeight: 600, lineHeight: '20px', color: '#23395D' }}>Adults</Typography>
                    <Typography style={{ fontSize: 12, lineHeight: '18px', color: '#667085' }}>Age 13 or Above</Typography>
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center', gap: 22 }}>
                    <Typography data-test-id="decreaseAdultCountTest" onClick={this.decreaseAdultCount} style={{ fontSize: 16, color: '#23395D', cursor: "pointer" }} >-</Typography>
                    <Typography style={{ fontSize: 16, color: '#23395D' }}>{this.state.adultsCount}</Typography>
                    <Typography data-test-id="IncreaseAdultCountTest" onClick={this.increaseAdultCount} style={{ fontSize: 16, color: '#23395D', cursor: "pointer" }}>+</Typography>
                  </Box>
                </Typography>
                <Typography style={{ padding: '16px 0px', borderBottom: '1px solid #EAECF0', color: '#23395D', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                  <Box>
                    <Typography style={{ fontSize: 14, fontWeight: 600, lineHeight: '20px', color: '#23395D' }}>Children</Typography>
                    <Typography style={{ fontSize: 12, lineHeight: '18px', color: '#667085' }}>Ages 2-12</Typography>
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center', gap: 22 }}>
                    <Typography data-test-id="decreaseChildrenCountTest" onClick={this.decreaseChildrenCount} style={{ fontSize: 16, color: '#23395D', cursor: "pointer"  }}>-</Typography>
                    <Typography style={{ fontSize: 16, color: '#23395D' }}>{this.state.childrenCount}</Typography>
                    <Typography data-test-id="increaseChildrenCountTest" onClick={this.increaseChildrenCount} style={{ fontSize: 16, color: '#23395D', cursor: "pointer"  }}>+</Typography>
                  </Box>
                </Typography>
                <Typography style={{ padding: '16px 0px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                  <Box>
                    <Typography style={{ fontSize: 14, fontWeight: 600, lineHeight: '20px', color: '#23395D' }}>Infants</Typography>
                    <Typography style={{ fontSize: 12, lineHeight: '18px', color: '#667085' }}>Under 2</Typography>
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center', gap: 22 }}>
                    <Typography data-test-id="decreaseInfantsCountTest" onClick={this.decreaseInfantsCount} style={{ fontSize: 16, color: '#23395D', cursor: "pointer"  }}>-</Typography>
                    <Typography style={{ fontSize: 16, color: '#23395D' }}>{this.state.infantsCount}</Typography>
                    <Typography data-test-id="increaseInfantsCountTest" onClick={this.increaseInfantsCount} style={{ fontSize: 16, color: '#23395D', cursor: "pointer"  }}>+</Typography>
                  </Box>
                </Typography>
              </Box>
              <Box style={{ padding: 16, borderTop: '1px solid #E7E7E7', textAlign: 'end' }}>
                <Button data-test-id="testingCancel"onClick={()=>{this.handleCancel()}} style={{ textTransform:"none", color: '#475467', borderRadius: 4, border: '1px solid #D0D5DD', padding: '10px 16px', marginRight: 12 }}>Cancel</Button>
                <Button data-test-id="applyBtn" onClick={this.handleGuestsCount} style={{ background: '#3173E1', borderRadius: 4, padding: '10px 16px', color: 'white', textTransform:"none" }}>Apply</Button>
              </Box>
            </Box>}
          </Box>
          <Box className='search-box' data-test-id="handleSearchTestID" onClick={this.handleSearchForCatalogue}>
            <img className='search-icon' style={{ marginRight: 8 }} src={Search} alt="" />
            Search
          </Box>
        </Box>
      </Box>
    </HeaderBox>
  </Box>
  };

  CardMapView=(data:any)=>{ return <Box sx={webStyle.productContainer}>
  <Card data-test-id="TestCarousel" onMouseEnter={this.handleCarouselOn} onMouseLeave={this.handleCarouselOff} style={{ maxWidth: '390px',minHeight : 314, width: '100%', borderRadius: 16, overflow: 'hidden' }} data-testid={"TestNavigate"}>
    <CardActionArea style={{ position: 'relative' }}  >
      <Box style={{ width: 50, padding: '3px 6px', background: 'white', color: '#22395D', borderRadius: 20, position: 'absolute', top: 10, left: 10, zIndex: 111, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
        {data.attributes.rate}
        <img style={{ height : 18, width: 18}} src={starIcon}></img>
      </Box>
      <Box style={{ width: 50, height: 20, borderRadius: 20, position: 'absolute', top: 10, right: 10, zIndex: 111, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
        <img src={HeartIcon}></img>
      </Box>
      <SliderParentBox>
      <SimpleImageSlider
      data-test-id="TestSlider"
      style={{objectFit: 'cover'}}
      width={"100%"}
      height={190}
      images={data.attributes?.images}
      showBullets={true}
      showNavs={false}
      autoPlay={false}
      autoPlayDelay={2.5}
      onClick={()=>{this.navigateToProductDescription(data.id)}}
       />
       </SliderParentBox>
      <Typography style={{ textAlign: "center", padding: '16px 0px 0px', marginBottom: 0, lineHeight: '24px',fontSize:16,color:"#23395D",fontWeight:600 }} data-test-id="testNavigate" onClick={()=>{this.navigateToProductDescription(data.id)}}>
      {data.attributes.features.home_title ?? "Beautiful Malibu Mansion"}
      </Typography>
    </CardActionArea>
    <CustomCardActions>
      <Box style={{ display: "flex", gap: 12, alignItems: "center" }}>
        <span style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", textAlign: "center" }}>
          <p style={{ alignItems: "center", marginBottom: 4, lineHeight: 20 }}><img src={this.state.listingName=="Home"?BedIcon:SeatOption}></img> </p>
          {this.state.listingName==="Home"?<p>{truthyBedValueValue(data)} bed</p>:<p>{data.attributes.specification.data?.attributes.capacity}</p>}
        </span>
        <span style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", textAlign: "center" }}>
          <p style={{ alignItems: "center", marginBottom: 4, lineHeight: 20 }}><img src={this.state.listingName=="Home"?ShowerIcon:FuelIcon}></img></p>
          {this.state.listingName==="Home"?<p>{truthyToiletValueValue(data)} Toilet</p>:<p>{data.attributes.specification.data?.attributes.fuel_efficiency}</p>}
        </span>
      </Box>
      <span style={{ fontSize: "20px", color: "#3173E1", fontWeight: 700, lineHeight: "20px", textAlign: "left" }}>
        ${data.attributes.total_nightly_price}/<span style={{ fontSize: "14px" }}>{this.state.listingName==="Home"?"NIGHT":"DAY"}</span>
      </span>
      <span style={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", textAlign: "center" }} >
        <p style={{ alignItems: "center", marginBottom: 4, lineHeight: 20 }}><img src={LocationIcon}></img></p>
        <p>2.6 mi </p>
      </span>
    </CustomCardActions>

  </Card>
</Box>}

  

  // Customizable Area End

  render() {
      // Customizable Area Start
      const center = { lat: 0, lng: 0 };
      // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        {this.AppHeader()}
        <FilterParentBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, maxWidth: 1220, width: '100%', margin: 'auto', marginTop: 40 }}>
          <Typography className="mobile-hide" style={{ width: '100%',fontWeight:400,fontSize:"20px",color:"#475467" }}>Over {this.handleCountOnDisplay()} listings are available</Typography>
          <CustomTabs>
        <Tabs listingVarientName={this.handleListingForCarAndHome} />
        </CustomTabs>
          <Box style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
            <button data-test-id="TestFilter" onClick={this.handleFilter} style={{
              border: '1px solid #D0D5DD', borderRadius: 30, padding: '8px 16px', background: 'white', fontSize: 14, fontWeight: 600, color:
                '#475569', display: 'flex', alignItems: 'center', gap: 8,cursor:"pointer"
            }}> <img src={filterIcon} />Filter</button>
                <FilterModal 
                   cataloygeType={this.state.listingName} 
                   homeTypeData={this.state.homeCatogoriesData} 
                   homeCatogoryId={this.state.homeCatogoryId as number}
                   carCatogoryId={this.state.carCatogoryId as number}
                   data-test-id="CloseFilterTest" 
                   open={this.state.openFilterModal} 
                   handleClose={this.filterModalClose}
                   submitFilterData={(payload: SearchCriteria) => this.getAdvancedSearcheData(payload)}
                />
           
            <button className="mobile-hide" style={{ border: '1px solid #D0D5DD', borderRadius: 30, padding: '8px 16px', background: 'white', fontSize: 14, fontWeight: 600, color: '#475569', display: 'flex', alignItems: 'center', gap: 8 ,cursor:"pointer"}}> <img src={sortIcon} />Latest  <img style={{ marginLeft: 6 }} src={dropDown} /></button>
            <TabBox className="mobile-hide">
              <Box className={`${this.state.mapView ? "first-child activeCardView" : "first-child side-border"}`} onClick={this.openMapView} data-test-id="mapView">
                <img className='home-icon' src={mapViewIcon} alt="" />
                Map View</Box>
              <Box className='first-child activeCardView' onClick={this.opencardView} data-test-id="cardView"><img className='car-icon' src={cardViewIcon} alt="" />Card View</Box>
            </TabBox>
          </Box>
        </FilterParentBox>
        <CardInnerBox style={{ display: "flex", gap: "24px", flexWrap: "wrap", maxWidth: 1220, margin: 'auto', justifyContent: 'center' }}>
          {this.state.errorResponse && this.state.errorResponse!="" &&<Typography>{this.state.errorResponse}</Typography>}
          
          {this.state.loader ? <Loader loading={true} /> :
            this.state.cardView && <Grid container spacing={3}>
              {this.state.listCard.map((data: any) => 
                <Grid item md={4} sm={6} xs={12}>
                  {this.CardMapView(data)}
                </Grid>
              )}
            </Grid>}

          {this.state.mapView &&
            <Grid container spacing={3} style={{ height: "100vh", width: "100vw" }}>
            <LoadScript googleMapsApiKey="AIzaSyDS3lSh1DVsIjUheUfsyk7UdUBKNKJkEVg">
              <GoogleMap
                mapContainerStyle={{ height: "100%", width: "100%" }}
                center={center}
                zoom={2}
              >
                {this.state.listCard.map((location: Location, index: number) => (
                  <Marker
                    key={index}
                    position={{
                      lat: location.attributes.address.latitude,
                      lng: location.attributes.address.longitude,
                    }}
                    onClick={() => this.setState({ selectedLocation: location })}
                  />
                ))}
    
                {this.state.selectedLocation && (
                  <InfoWindow
                    position={{
                      lat: this.state.selectedLocation.attributes.address.latitude,
                      lng: this.state.selectedLocation.attributes.address.longitude,
                    }}
                    onCloseClick={() => this.setState({ selectedLocation: null })}
                  >
                    <div>
                      {this.state.selectedLocation.attributes.address.city},{" "}
                      {this.state.selectedLocation.attributes.address.country}
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>
          </Grid>
          }
          
            {(this.state.listCard.length > 0) && <Box style={{ display: "flex", width: '100%', justifyContent: "space-between",paddingBottom: 50 }}>
            <Box style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
            <CustomPagination
            data-test-id="testCustomPagination"
              count={Math.ceil(this.state.totalPages)}
              page={this.state.activePage}
              onChange={this.handleChange}
              siblingCount={0}
              boundaryCount={3}
            />
            <ShowPageBox style={{display: 'flex', alignItems: 'center', gap: '13px', fontSize: '14px', fontWeight: 700, color: '#475467',cursor:"pointer"}}>
              Show:
                <Select
                  value={this.state.perPageData}
                  onChange={this.perPageDataChange}
                  disableUnderline
                  data-test-id="perPage"
                  className="paginationText"
                >
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                </Select>
              
            </ShowPageBox>
            </Box>
           <NextPreBox style={{ display: "flex" }} >
           {this.state.activePage > 1 &&<Box style={{ display: "flex", gap: 8, cursor:"pointer",alignItems: 'center' }}>
                <img style={{ width: "20px" }} src={ArrowLeft}></img>
                <Typography data-test-id="testPreviousPageChange" onClick={this.handlePreviousPage}>Previous</Typography>
              </Box>}
              {this.state.activePage < this.state.totalPages &&<Box style={{ display: "flex", gap: 8, alignItems: 'center', marginLeft: "14px",cursor:"pointer" }}>
                <Typography data-test-id="testNextPage" onClick={this.handleNextPage}>Next</Typography>
                <img style={{ width: "20px" }} src={ArrowRight}></img>
              </Box>}
            </NextPreBox>
          </Box>}
          
        </CardInnerBox>

        <CardInnerBox style={{ display: "flex", gap: "24px", flexWrap: "wrap", maxWidth: 1220, margin: 'auto', justifyContent: 'center' }}>
          {this.state.loader ? <Loader loading={true} /> :
            <Grid container spacing={3}>
              {this.state.searchData.map((searchData: any) => 
                <Grid item md={4} sm={6} xs={12}>
                  {this.CardMapView(searchData)}
                </Grid>
              )}
            </Grid>}
          
        </CardInnerBox>
        <Footer />
      </ThemeProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  modal:{display: 'flex',
  padding: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',},
  productContainer: {
    // display: "flex",
    // flexDirection: "row",
    // flexWrap: "wrap",
    // width: "32%",
    backgroundColor: "white",
    marginTop: "20px",
    '@media(max-width: 960px)': {
      marginTop: '14px !important',
      width: "48%",
    },
    '@media(max-width: 625px)': {
      width: "97%!important",
    }
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
