// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    Radio,
    Button
} from "@material-ui/core"

import PostTripCommonSidebar from "../../../components/src/PostTripCommonSidebar.web"

import AddIcon from '@material-ui/icons/Add';
import { checkedIcon, radioIcon, tipsIcon } from "./assets";



import PostTripCheckoutFlowController, {
    Props,
  } from "./PostTripCheckoutFlowController";


class PostTripCheckoutFlow extends PostTripCheckoutFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    renderPhotosSection = () => {
        return (
            <Box className="carBox">
                <div className="addphotos">
                    {this.state.images.map((image, index) => (
                        <div key={index} style={{ border: '1px dashed #D0D5DD', position: 'relative', height: 130, borderRadius: 12, margin: "8px 0" }}>
                            {image.preview ? (
                                <img src={image.preview} alt={`Uploaded ${index}`} style={{ width: '100%', height: '100%', borderRadius: 12 }} />
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        data-test-id="imageGrid"
                                        onChange={(event) => this.handleImageUpload(event, index)}
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            cursor: 'pointer',
                                        }}
                                    />
                                    <div style={{ textAlign: 'center', display: "flex", gap: 5, alignItems: "center" }}>
                                        <Box>
                                            <AddIcon className="colorText" />
                                        </Box>
                                        <Typography className="addPhotoText"> Add Photo</Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </Box>
        )
    }


  render() {
    // Customizable Area Start
    
    return (
        <div>
            <Wrapper>
                <PostTripCommonSidebar navigation={this.props.navigation} id="postTripCheckOutFlow">

                    <Box>
                        <Typography className="addPhotosText"> Add Photos </Typography>
                        <Typography className="addPhotossubText">Please take up to 9 photos to document the car's condition. Include interior and exterior. The Hosts photos will show up here also.</Typography>
                    </Box>

                    {this.renderPhotosSection()}

                    <Box className="tipsBox">
                        <Box style={{ display: "flex", gap: 10, margin: "10px 0" }}>
                            <img src={tipsIcon} />
                            <Typography className="tipsText">Tips & Guide</Typography>
                        </Box>
                        <Box>
                            <AddIcon style={{ color: "#22395D", height: 24, width: 24 }} />
                        </Box>
                    </Box>


                    <Box>
                        <Typography className="addPhotosText"> Visible Damage </Typography>

                        <Box className="radioTextBox">
                            <Typography className="damageText"> Is there any significant damage?</Typography>
                            <Box style={{ display: "flex", gap: 10, margin: "10px 0" }}>
                                <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                    <Radio icon={<img src={radioIcon} />} checkedIcon={<img src={checkedIcon} />} onChange={this.handleChangeforDamageKey} value={"true"} checked={this.state.damageValue === "true"} name="visibleDamage" />
                                    <Typography className="radioText"> Yes</Typography>
                                </Box>
                                <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                    <Radio icon={<img src={radioIcon} />} checkedIcon={<img src={checkedIcon} />} value={"false"} onChange={this.handleChangeforDamageKey} checked={this.state.damageValue === "false"} name="visibleDamage" />
                                    <Typography className="radioText"> No</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                        <Button data-test-id="backBtn" onClick={this.navigateToPreviousPage} className="backButtonCheckout">Back</Button>
                        <Button data-test-id="nextBtn" onClick={this.saveCheckOutImages} className="continueButtonCheckout">Continue</Button>
                    </Box>

                </PostTripCommonSidebar>
            </Wrapper>
        </div>
    );
    // Customizable Area End
  }
}

export default PostTripCheckoutFlow;

const Wrapper  = styled(Box)({
    "& .addphotos": {
        display: 'grid', 
        gridTemplateColumns: 'repeat(3, 1fr)', 
        gap: '15px',
        "@media(max-width: 420px)": {
            display: 'grid', 
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
    "& .addPhotosText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        textAlign: 'left',
        color:"#23395D",
    },
    "& .addPhotossubText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'left',
        color:"#475467",
        margin: "5px 0"
    },
    "& .addPhotoText" : {
        color : "#3173E1",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center'
    },
    "& .damageText" : {
        color : "#23395D",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        margin: "10px 0"
    },
    "& .radioText" : {
        color : "#475467",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        
    },
    "& .tipsBox" : {
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 20px",
        border: "1px solid #EAECF0",
        borderRadius: "12px",
        alignItems: "center",
        margin: "25px 0"
    },
    "& .radioTextBox" : {
        padding: "10px 20px",
        border: "1px solid #EAECF0",
        borderRadius: "12px",
        alignItems: "center",
        margin: "10px 0"
    },
    "& .tipsText" : {
        color : "#23395D",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        margin: "10px 0"
    }, 
    "& .continueButtonCheckout" : {
        width: '150px',
        height: '48px',
        gap: '8px',
        backgroundColor: '#3173E1',
        textTransform: "none",
        borderRadius: '12px',
        color: 'white',
        margin: "15px 0",
        padding: '14px 16px',
        fontWeight: "bold",
      },
      "& .backButtonCheckout" : {
        borderRadius: '12px',
        gap: '8px',
        color: '#475467',
        height: '48px',
        border: "1px solid #D0D5DD",
        fontWeight: "bold",
        backgroundColor: 'white',
        padding: '14px 16px',
        margin: "15px 0",
        width: '115px',
        textTransform: "none"
      },
})

// Customizable Area End