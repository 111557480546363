// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import moment from "moment";
import { CatalogeSummary, HomeReservationSummary, HostInfo, PriceBreakDown } from "../../../components/src/TypeInterfaces.web";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  children? : any,
  pageTitle? : string,
  sveCheckoutImages? : () => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  loading: boolean,
  bookedSlotId: string,
  catalogueId : string,
  conversationId: string,
  hostInfo : HostInfo,
  homeReservationInfo : HomeReservationSummary,
  pricingBreakDown : PriceBreakDown[],
  catalogeInfo : CatalogeSummary,
  totalTripAmount : string,
  tripEndTime : string,
  tabCount : number,
  checkOutModel : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostTripReservationFlowController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBookedSlotInfoApiCallId : string = ""
  getTripCostBreakdownInfoApiCallId : string = ""
  getTripInfoApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        loading: true,
        bookedSlotId : "",
        catalogueId : "",
        conversationId : "",
        hostInfo: this.initialHostInfo,
        homeReservationInfo: this.initialHomeSummary,
        pricingBreakDown: this.initialPriceSteps,
        catalogeInfo: this.initialCatalougeInfo,
        totalTripAmount: "",
        tripEndTime : "",
        tabCount : 0,
        checkOutModel : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getBookedSlotInfoApiCallId:
            return this.getBookedSlotResponse(responseJson)
        case this.getTripCostBreakdownInfoApiCallId : 
            return this.getPricngResponse(responseJson)
        default:
            break;
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    const bookedSlotId = this.props.navigation.getParam("bookSlotId") 
    this.setState({ bookedSlotId : bookedSlotId}) 
    this.getBookedSlotData(bookedSlotId)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.bookedSlotId !== this.state.bookedSlotId) {
        this.getPricingData()
      }
  }

  getBookedSlotData = ( bookedSlotId : number) => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getBookedSlotInfoApiCallId = apiCalling({
        endPoint: `${configJSON.getReservationDetailApiEndPoint}/${bookedSlotId}`,
        header: JSON.stringify(header),
        method: configJSON.apiTypeGet
    })

  }

  getPricingData = () => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getTripCostBreakdownInfoApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: `${configJSON.getPriceBreakdownApiEndPoint}?id=${this.state.bookedSlotId}`,
        method: configJSON.apiTypeGet
    })

  }

    getBookedSlotResponse = (responseJson: any) => {

        if (responseJson && responseJson.catalogue && responseJson.catalogue.data) {
            const modifiedObj = responseJson.catalogue.data.attributes
            const reservationStartDate = moment(responseJson.catalogue.data.attributes.start_time).format('MMM Do, YY, h:mm A')
            const reservationEndDate = moment(responseJson.catalogue.data.attributes.end_time).format('MMM Do, YY, h:mm A')
            const tripEndTime = this.handleEndTripTime(modifiedObj.end_time)
            const conversationId = responseJson.catalogue.data.attributes.conversation && truthyValue(responseJson.catalogue.data.attributes.conversation.id)

            const hostInfo = {
                hostName: truthyValue(modifiedObj.hosted_by.data.attributes.hosted_by.full_name),
                hostImg: truthyValue(modifiedObj.hosted_by.data.attributes.host_profile_image.url),
                hostDuration: truthyValue(modifiedObj.hosted_by.data.attributes.hosted_by.joined),
                hostTrips: truthyValue(modifiedObj.hosted_by.data.attributes.hosted_by.host_trip_completed)
            }

            const homeReservationInfo = {
                checkIn: reservationStartDate,
                checkOut: reservationEndDate,
                guestCount: "2",
                pickUpLocation: modifiedObj.address.length > 0 ? `${truthyValue(modifiedObj.address[0].address)},${truthyValue(modifiedObj.address[0].country)}` : ""
            }

            const catalogeInfo = {
                catalougeId: truthyValue(modifiedObj.catalogue_id),
                catalougeName: truthyValue(modifiedObj.catalogue_name),
                catalougeImg: this.handleCatalougeImages(responseJson.catalogue.data),
                reviews: truthyValue(modifiedObj.rating.reviews_count),
                rating: truthyValue(modifiedObj.rating.average_rating)
            }

            const catalogueId = truthyValue(modifiedObj.catalogue_id)

            this.setState({ hostInfo, homeReservationInfo, tripEndTime, catalogeInfo, loading: false, catalogueId, conversationId })
        } else {
            this.setState({ loading: false })
        }
    }

    getPricngResponse = (responseJson: any) => {
        if(responseJson && responseJson.trip_cost_and_trip_cost_break_down) {
            const receivedObj = responseJson.trip_cost_and_trip_cost_break_down
    
            const modifedPriceData: PriceBreakDown[] = [
              {
                label: 'Rental Price',
                details: `$${truthyValue(receivedObj.per_day)} / Night`,
                subDetails: `(x${truthyValue(receivedObj.number_of_days)} Night)`,
                amount: `$${truthyValue(receivedObj.rental_price)}`,
              },
              {
                label: `Discount`,
                details: 'Extended Trip Discount',
                subDetails: `(${truthyValue(receivedObj.discounts_day)}%)`,
                amount: `$${truthyValue(receivedObj.discounts_value)}`,
              },
              {
                label: 'Trip Payment',
                details: `Refundable Deposit $${truthyValue(receivedObj.refundable_deposit)}`,
                subDetails: "",
                amount: `$${truthyValue(receivedObj.total_price_due)}`,
              },
            ]; 
    
            const amount =` $${truthyValue(receivedObj.total_price_due)}`
    
            this.setState({ pricingBreakDown : modifedPriceData, totalTripAmount : amount})
        } else {
          this.setState({ pricingBreakDown : this.initialPriceSteps})
        }
      }

  initialHostInfo : HostInfo = {
    hostName : "",
    hostImg : "",
    hostDuration: "",
    hostTrips : ""
  }

  initialHomeSummary : HomeReservationSummary = {
    checkIn : "",
    checkOut : "",
    guestCount : "",
    pickUpLocation : ""
  }

  initialCatalougeInfo : CatalogeSummary = {
    catalougeId : "",
    catalougeName : "",
    catalougeImg : "",
    reviews : "",
    rating : ""
  }

  initialPriceSteps: PriceBreakDown[] = [
    {
      label: 'Rental Price',
      details: '$1,400 / Night',
      subDetails: "(x3 Night)",
      amount: '$0',
    },
    {
      label: '3+ Day Discount',
      details: 'Extended Trip Discount',
      subDetails: "(5%)",
      amount: '$0',
    },
    {
      label: 'Trip Payment',
      details: 'Refundable Deposit $500',
      subDetails: "",
      amount: '$0',
    },
  ];

  navToBack = () => {
    this.props.navigation.goBack()
  }

  navToCheckoutPage = () => {
    this.props.navigation.navigate("PostTripFlowCheckout", { bookSlotId : this.state.bookedSlotId})
  }

  handleTab = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tabCount: value})
  }

  handleEndTripTime = (endTime: string) => {
    const currentTime = moment();
    const startMoment = moment(endTime);
    const difference = startMoment.diff(currentTime, 'hours');
    const days = Math.floor(difference / 24);

    if (difference < 48) {
        return `${difference} Hours.`
    } else {
        return `${days} Days`
    }
  }

  handleCatalougeImages = (item : any) => {
    // imageUrl
    let imgUrl : string = ""
    if(item.attributes && item.attributes.images.length > 0 && item.attributes.images[0].url ) {
      imgUrl = item.attributes.images[0].url
    }

    return imgUrl 
  }

  closeModel = () => {
    this.setState({ checkOutModel : false})
  }

  openModel = () => {
    this.setState({ checkOutModel : true})
  }

  // Customizable Area End
}

// Customizable Area End