// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Tab,
    Tabs,
    Typography,
    Grid,
    Card
} from "@material-ui/core"

import PreTripCommonSidebar from "../../../components/src/PreTripCommonSidebar.web";
import ReservationChat from "../../../blocks/chat/src/ReservationChat.web";

import { amentitiesIcon, receipt1, receipt2, specificationIcon, starIcon } from "./assets";
import { Verified1 } from "../../../blocks/productdescription/src/assets";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import StarIcon from '@material-ui/icons/Star';

import { InstructionData } from "../../../components/src/TypeInterfaces.web";


import PreTripReservationFlowController, {
    Props,
  } from "./PreTripReservationFlowController";


class PreTripReservationFlow extends PreTripReservationFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderInstructionCard = (array : InstructionData[], headerValue : string) => {
     return (
         <Box className="mainInstructionBox">
             <Box className="grayInstructionBox">
                 <Box className="headerInstructionBox">
                     <Typography className="subPolicyText">{headerValue}</Typography>
                 </Box>
             </Box>
             <Box className="whiteInstructionBox">
                 {array.map(item =>
                     <Box className="flexDiv" key={item.instructionID}>
                         <CheckCircleIcon className="listIcon" />
                         <Typography className="titleText">{item.cardTitle}</Typography>
                     </Box>
                 )}
             </Box>
         </Box>
     )
  }

  renderCommonHeaderTitle = ( title : string) => {
     return (
        <Typography className="headerTabText">{title}</Typography>
     )
  }

  renderRegistrationBox = () => {
    return (
        <Box className="registration-parent-box" style={{ display: "flex", gap: 12, width: "100%", marginTop: 24 }}>
            <Box className="registration-box" style={{ padding: 20, border: '1px solid #EAECF0', borderRadius: 12, flex: 1 }}>
                <Typography className="reg-first" variant="body1" style={{ color: "#475467" }}>Registration number:</Typography>
                <Typography variant="body1" style={{ color: "#475467", fontSize: "18px", lineHeight: "28px", fontWeight: 500 }}>PCC-000917</Typography>
            </Box>
            <Box className="registration-box" style={{ padding: 20, border: '1px solid #EAECF0', borderRadius: 12, flex: 1 }}>
                <Typography variant="body1" style={{ color: "#475467" }}>Response rate:</Typography>
                <Typography variant="body1" style={{ color: "#475467", fontSize: "18px", lineHeight: "28px", fontWeight: 500 }}>100%</Typography>
            </Box>
            <Box className="registration-box" style={{ padding: 20, border: '1px solid #EAECF0', borderRadius: 12, flex: 1 }}>
                <Typography variant="body1" style={{ color: "#475467" }}>Response time:</Typography>
                <Typography variant="body1" style={{ color: "#475467", fontSize: "18px", lineHeight: "28px", fontWeight: 500 }}>within an hour</Typography>
            </Box>
        </Box>
    )
  }

    renderDetailsTab = () => {
        return (
            <Box style={{ marginTop : 20}}>
                {this.renderCommonHeaderTitle("Description")}

                <Box style={{ fontSize: "16px", marginBottom: "32px" }}>
                    <Typography className="paraText" style={{ marginTop: 15}}>
                        Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla. Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus.
                    </Typography>
                    <Typography style={{ marginTop : 10}}>
                        <ul>
                            <li className="paraText">
                                Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id.Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.
                            </li>
                            <li className="paraText">
                                Non pellentesque congue eget consectetur turpis.
                            </li>
                            <li className="paraText">
                                Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.            </li>
                        </ul>
                    </Typography>
                </Box>

                {this.renderCommonHeaderTitle("Apartment Specifications ")}

                <Box style={{ marginTop : 15, marginBottom : 30}}>
                    <img src={specificationIcon} alt="" />
                </Box>

                {this.renderCommonHeaderTitle("More Images")}

                <Box style={{ marginTop : 15, marginBottom : 30}}>
                    <Grid container spacing={3}>
                        {this.state.catalougesImages.map((stringVal: any) => {
                            return (
                                <Grid item md={6}>
                                    <img style={{ height: 175, width: "100%", borderRadius: 26 }} src={stringVal.url}></img>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>

                {this.renderCommonHeaderTitle("Amenities")}

                <Box style={{ marginTop : 15, marginBottom : 30}}>
                    <img src={amentitiesIcon} alt="" />
                </Box>

                <Box className="aboutHostBox">
                    <Box style={{ marginTop: 32 }}>
                        <Card style={{ boxShadow: 'none' }}>
                            <Box style={{ display: "flex", gap: 15 }}>
                                <img src={this.state.hostDetails.hostImg} style={{ width: 58, height: 58, borderRadius: '100%' }} alt="" />
                                <Box style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography style={{ letterSpacing: 'normal', color: '#23395D', fontSize: 20, fontWeight: 600, textTransform : "capitalize" }}>
                                        {this.state.hostDetails.hostName}
                                    </Typography>
                                    <Box style={{ display: "flex", gap: 29, }}>
                                        <Typography>{this.state.hostDetails.hostTrips} trips</Typography>
                                        <Typography>Host since {this.state.hostDetails.hostDuration}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", gap: 14 }}>
                                <Box style={{ display: "flex", gap: 8, alignItems: 'center' }}>
                                    <StarIcon style={{ width: "16px", height: "16px" }} />
                                    <Typography>{this.state.catalogeDetails.reviews} reviews</Typography>
                                </Box>
                                <Box style={{ display: "flex", gap: 8, alignItems: 'center' }}>
                                    <img style={{ width: "16px" }} src={Verified1}></img><Typography>Identity Verified</Typography>
                                </Box>
                            </Box>
                            <Box style={{ marginTop: "24px" }}>
                                <Typography style={{ letterSpacing: 'normal', fontWeight: 600, fontSize: "16px", marginBottom: "12px", color: '#23395D' }}>About Host</Typography>
                                <Typography>
                                    Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla. Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus.
                                </Typography>
                            </Box>
                             {this.renderRegistrationBox()}
                        </Card>
                    </Box>
                </Box>

                  
                
            </Box>
        )
    }

    renderTripInfoTab = () => {
        return (
            <Box style={{ marginTop : 20}}>
                <Typography className="policyNameText">{this.mainInstructionHeader.housePoliciyHeader}</Typography>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        {this.renderInstructionCard(this.state.checkInArray, this.subInstructionHeader.checkInDateTimeHeader)}
                    </Grid>

                    <Grid item md={6}>
                        {this.renderInstructionCard(this.state.houseRuleArray, this.subInstructionHeader.houseRuleHeader)}
                    </Grid>
                </Grid>

                <Typography className="policyNameText">{this.mainInstructionHeader.checkInInstructionHeader}</Typography>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        {this.renderInstructionCard(this.state.locationArray, this.subInstructionHeader.locationDetailsHeader)}
                    </Grid>
                    <Grid item md={6}>
                        {this.renderInstructionCard(this.state.accessMethodArray, this.subInstructionHeader.accessMethodHeader)}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        {this.renderInstructionCard(this.state.parkingArray, this.subInstructionHeader.partkingInstructionHeader)}
                    </Grid>
                    <Grid item md={6}>
                        {this.renderInstructionCard(this.state.wifiArray, this.subInstructionHeader.wifiInstructionHeader)}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={6}>
                        {this.renderInstructionCard(this.state.otherPropertyArray, this.subInstructionHeader.otherPropertyDetailsHeader)}
                    </Grid>
                    <Grid item md={6}>
                        {this.renderInstructionCard(this.state.localRecommandedArray, this.subInstructionHeader.localRecommandedHeader)}
                    </Grid>
                </Grid>
            </Box>
        )
    }

    renderChatTab = () => {
        return (
            <Box>
                <ReservationChat
                    receiverId={this.state.conversationId}
                    navigation={this.props.navigation}
                    id="ChatBlock"
                    updateCount={() => {}} />
            </Box>
        )
    }

    renderReceiptTab = () => {
        return (
            <Box>
                <Typography className="docText">Documents</Typography>

                <Box className="imgBox">
                    <img src={receipt1} alt="receipt1" />
                    <img src={receipt2} alt="receipt2" />
                </Box>
            </Box>
        )
    }

    renderAllTab = () => {
        switch (this.state.tabValue) {
            case 0:
                return this.renderDetailsTab()
            case 1:
                return this.renderTripInfoTab()
            case 2:
                return this.renderChatTab()
            case 3:
                return this.renderReceiptTab()

        }
    }

  render() {
    // Customizable Area Start
    
    return (
        <div>
            <Wrapper>
                <PreTripCommonSidebar id="preTrip" pageTitle="preTripInfo" navigation={this.props.navigation}>
                    <Box className="breadcrumbsBox">
                        <Typography className="breadcrumbText" onClick={this.navigateToReservationPage} data-test-id="bredcrumbOne">Reservations</Typography>
                        <ArrowForwardIosIcon style={{ height: 14, color: "#64748B" }} fontSize="small" />
                        <Typography className="breadcrumbText"><span className="colorText">{this.state.catalogeDetails.catalougeName}</span></Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: 20, marginBottom: "30px" }}>
                        <img src={this.state.catalogeDetails.catalougeImg} style={{ width: 132, height: 114, borderRadius: "12px" }} />
                        <Box>
                            <Typography className="catalogeName">{this.state.catalogeDetails.catalougeName}</Typography>
                            <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                                <img src={starIcon} />
                                <Typography className="reviewText">  {this.state.catalogeDetails.rating} <span> {this.state.catalogeDetails.reviews} reviews</span></Typography>
                            </div>

                        </Box>
                    </Box>
                    <Box>
                        <Tabs
                            className="mainTabsCSS"
                            value={this.state.tabValue}
                            onChange={this.handleTabChanges}
                            data-test-id="tabs"
                        >
                            <Tab label="Home Details" className="tabTextCSS"></Tab>
                            <Tab label="Trip Info" className="tabTextCSS"></Tab>
                            <Tab label="Messages" className="tabTextCSS"></Tab>
                            <Tab label="Receipt" className="tabTextCSS"></Tab>
                        </Tabs>

                        {this.renderAllTab()}
                    </Box>
                </PreTripCommonSidebar>
            </Wrapper>
        </div>
    );
    // Customizable Area End
  }
}

export default PreTripReservationFlow;

const Wrapper  = styled(Box)({
    "& .tabTextCSS": {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textTransform: "none",
        color: "#475467"
      },
      "& .mainTabsCSS": {
        borderBottom: "1px solid #E2E8F0",
      },
      "& .mainTabsCSS .Mui-selected": {
        color: "#3173E1",
        borderBottom: "none"
      },
      "& .mainTabsCSS .MuiTabs-indicator": {
        backgroundColor: "#3173E1"
      },
      "& .docText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: "#23395D",
        margin: "20px 0"
      },
      "& .imgBox" : {
         display : "flex",
         gap : 20
      },
      "& .policyNameText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '24px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .subPolicyText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .mainInstructionBox" : {
        border: "1px solid #E7E7E7",
        borderRadius: "12px",
        margin: "15px 0"
      },
      "& .grayInstructionBox" : {
        background : "#F4F7FC",
        borderBottom: "1px solid #E7E7E7",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
      },
      "& .headerInstructionBox" : {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
      }, 
      "& .whiteInstructionBox" : {
        background :"white",
        padding: "15px",
        borderRadius: "12px",  
      },
      "& .titleText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 700,
        color: '#23395D',
      },
      "& .flexDiv" : {
        display: "flex",
        gap : "10px",
        margin: "10px 0"
      },
      "& .listIcon" : {
        color: "#3173E1", 
        height: 20, 
        width: 20,
      },
      "& .headerTabText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D',
      },
      "& .paraText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#475467',
      }
})

// Customizable Area End