// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    Button,
    Tab,
    Tabs,
    Dialog,
    IconButton
} from "@material-ui/core"

import PostTripCommonSidebar from "../../../components/src/PostTripCommonSidebar.web"
import ReservationChat from "../../../blocks/chat/src/ReservationChat.web";

import { receipt1, receipt2 } from "./assets";
import { tripEndIcon } from "../../../blocks/catalogue/src/assets";
import { checkOutImageIcon } from "../../../components/src/asset";
import CloseIcon from '@material-ui/icons/Close';


import PostTripReservationFlowController, {
    Props,
  } from "./PostTripReservationFlowController";


class PostTripReservationFlow extends PostTripReservationFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderTabOne = () => {
      return (
          <Box>

              <Box className="outerBox">
                  <Box>
                      <Typography className="textDetail1">Trip cost</Typography>
                      <Typography className="textDetail2">Click for breakdown</Typography>
                  </Box>
                  <Box>
                      <Typography className="infoColorText">$0</Typography>
                      <Typography className="textDetail3">+$0 deposit</Typography>
                  </Box>
              </Box>

              <Box className="outerBox">
                  <Typography className="textDetail1">Your Trip Ends in: <span className="infoColorText"> {this.state.tripEndTime} </span></Typography>
                  <Button className="modifiedTripBtn">Modify Trip</Button>
              </Box>

              <Box className="outerBox bgOuterBox">
                  <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <img src={tripEndIcon} alt="tripEndIcon" />
                      <Box>
                          <Typography className="textDetail1">End Trip</Typography>
                          <Typography className="textDetail2">Your trip is ending soon. To end the trip please follow the check-in steps</Typography>
                      </Box>
                  </Box>
                  <Button className="modifiedTripBtn" data-test-id="startCheckInBtn" onClick={this.openModel} style={{ width: 160, background: "#EDF4FF" }}>Start Check-Out</Button>
              </Box>
          </Box>
      )
  }

  renderTabTwo = () => {
    return (
        <Box>
            <ReservationChat
                receiverId={this.state.conversationId}
                navigation={this.props.navigation}
                id="chatSection"
                updateCount={() => { }} />
        </Box>
    )
  }

  renderTabThree = () => {
    return (
        <Box>
            <Typography className="docText">Documents</Typography>

            <Box className="imgBox">
                <img src={receipt1} alt="receipt1" />
                <img src={receipt2} alt="receipt2" />
            </Box>
        </Box>
    )
  }

  renderAllTabs = () => {
     switch(this.state.tabCount) {
        case 0 : 
           return this.renderTabOne()
        case 1 :
            return this.renderTabTwo()
        case 2 :
            return this.renderTabThree()
     }
  }

  render() {
    // Customizable Area Start
    
    return (
        <div>
            <Wrapper>
                <PostTripCommonSidebar navigation={this.props.navigation} id="postTripFlow">
                    <Box>
                        <Tabs
                            className="tabHeader"
                            value={this.state.tabCount}
                            onChange={this.handleTab}
                            data-test-id="tabs"
                        >
                            <Tab label="Home Details" className="tabLabel"></Tab>
                            <Tab label="Messages" className="tabLabel"></Tab>
                            <Tab label="Receipt" className="tabLabel"></Tab>
                        </Tabs>

                        {this.renderAllTabs()}  
                    </Box>

                </PostTripCommonSidebar>
            </Wrapper>

            <DialogWrapper
              open={this.state.checkOutModel}
              onClose={this.closeModel}
              fullWidth
              data-test-id="closeModal"
              maxWidth="sm"
            >

              <Box style={{ textAlign : "center", marginTop: "20px", position: "relative" }}>
                <img src={checkOutImageIcon} alt="checkOutImage" />
                <IconButton style={{ marginRight: 20, position : "absolute", right : 10 }} edge="end" color="inherit" data-test-id="closeIcon" onClick={this.closeModel} aria-label="close">
                  <CloseIcon style={{ color: "#64748B" }} />
                </IconButton>
              </Box>

                <Box textAlign={"center"} style={{ margin: "20px 0" }}>
                    <Typography className="startTripHeader">Start Checkout</Typography>
                    <Typography className="startTripInfo">
                        Welcome back!, We hope you had a fantastic
                    </Typography>
                    <Typography className="startTripInfo">
                        stay at {this.state.catalogeInfo.catalougeName}.
                    </Typography>
                    <Button className="checkOutBtnModal" onClick={this.navToCheckoutPage} data-test-id="navToCheckout">Start Checkout</Button>
                </Box>
            </DialogWrapper>
        </div>
    );
    // Customizable Area End
  }
}

export default PostTripReservationFlow;

const Wrapper  = styled(Box)({
      "& .tabLabel": {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textTransform: "none",
        color: "#475467"
      },
      "& .tabHeader": {
        borderBottom: "1px solid #E2E8F0",
      },
      "& .tabHeader .Mui-selected": {
        color: "#3173E1",
        borderBottom: "none"
      },
      "& .tabHeader .MuiTabs-indicator": {
        backgroundColor: "#3173E1"
      },
      "& .docText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: "#23395D",
        margin: "20px 0"
      },
      "& .imgBox" : {
         display : "flex",
         gap : 20
      },
      "& .outerBox" : {
        padding: "20px",
        borderRadius: "12px",
        alignItems: "center",
        border: "1px solid #DADBDE",
        justifyContent: "space-between",
        display: "flex",
        margin:"25px 0"
    }, 

    "& .textDetail1" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .textDetail2" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '12px',
        color: '#2A5591',
    },
    "& .textDetail3" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        color: '#94A3B8',
        fontSize: '12px',
    },
    "& .bgOuterBox" : {
        background: "#EDF4FF",
        border: "none !important"
    }, 
    "& .infoColorText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#3173E1',
        textAlign: "end"
    },
    "& .modifiedTripBtn": {
        fontFamily: 'Plus Jakarta Sans',
        height: "40px",
        width:"115px",
        fontSize: '14px',
        fontWeight: 500,
        textTransform: "none",
        color: '#3173E1',
        background: "white",
        border: "1px solid #3173E1",
        borderRadius: "12px"
    },
})

const DialogWrapper = styled(Dialog)({
    "& .startTripHeader": {
      color: "#23395D",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '24px',
      fontWeight: 600,
      marginBottom : "10px"
    },
    "& .startTripInfo": {
      color: "#475467",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 500,
    },
    "& .checkOutBtnModal": {
      height: "40px",
      fontFamily: 'Plus Jakarta Sans',
      marginTop: "30px",
      fontSize: '14px',
      fontWeight: 600,
      textTransform: "none",
      borderRadius: 30,
      textAlign: 'left',
      color: "white",
      background: "#3173E1",
      width: "200px"
    },
  })

// Customizable Area End