// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    Typography,
    Button,
} from "@material-ui/core"


import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import ProfileSidebar from "../../../components/src/ProfileSidebar.web";
import ProfileHeader from "../../../components/src/ProfileHeader.web";
import Loader from "../../../components/src/Loader.web";

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { carIcon, fileIcon, homeIcon } from "./assets";

import ApprovalStatusController, {
    Props,
  } from "./ApprovalStatusController";


class ApprovalStatus extends ApprovalStatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  handleVerificationStep = () => {
    switch (this.state.verificationSteps) {
        case 1:
            return this.renderStep1()
        case 2:
            return this.renderStep2()
        case 3:
            return this.renderStep3()
        case 4:
            return this.renderStep4()
        case 5:
            return this.renderStep5()
    }
  }

  handleIcons = () => {
    switch (this.state.verificationSteps) {
        case 3:
            return <CheckCircleOutlineIcon style={{ height: 18, width: 18, color: "#3173E1" }} />
        case 4:
            return <ErrorOutlineIcon style={{ height: 18, width: 18, color: "#FFB84E" }} />
        case 5:
            return <CancelOutlinedIcon style={{ height: 18, width: 18, color: "#CB0A0A" }} />
    }
  }

  renderStep1 = () => {
    return (
        <Box>
            <Typography className="stepHeaderText">Complete the verification:</Typography>

            <Box className="drivingLicenseBox" data-test-id="licence" onClick={this.handleLicenceRefCall}>
                    {this.state.licenceDoc !== null ? <CheckCircleIcon style={{ height : 18, width : 18, color: "#3173E1"}} /> :  <ErrorOutlineIcon style={{ height : 18, width : 18, color: "#CB0A0A"}} />}
                    <Typography className="infoStepText">Upload an active drivers license</Typography>
                    <input
                        type="file"
                        data-test-id={"licenseRef"}
                        onChange={this.handleLicenceChange}
                        ref={this.licenceImgRef}
                        style={{ display: "none" }}
                    />
            </Box>

            <Box className="drivingLicenseBox" data-test-id="selfie" onClick={this.handleSelfieRefCall}>
                    {this.state.selfieDoc !== null ? <CheckCircleIcon style={{ height : 18, width : 18, color: "#3173E1"}} /> :  <ErrorOutlineIcon style={{ height : 18, width : 18, color: "#CB0A0A"}} />}
                    <Typography className="infoStepText">Upload a selfie</Typography>
                    <input
                        type="file"
                        data-test-id={"selfieImgRef"}
                        onChange={this.handleSelfieChange}
                        ref={this.selfieImgRef}
                        style={{ display: "none" }}
                    />
            </Box>

            <Button className="primaryBtn" data-test-id="completeBtn" onClick={this.goToStep2}>Start Verification</Button>
        </Box>
    )
  }

  renderStep2 = () => {
    return (
        <Box>
            <Typography className="stepHeaderText">Verification in progress</Typography>

            {this.state.isDisplayed ?
                <Typography className="subStepTxt"> If you feel this is taking longer than usual (more than 3 hours), feel free to contact support.</Typography>
                :
                <Typography className="subStepTxt"> Thank you for reaching out! We've received your request and are currently looking into it. Our team will get back to you with an update as soon as possible. We appreciate your patience and understanding! </Typography>
            }
            
            {this.state.isDisplayed && <Button className="primaryBtn" data-test-id="supportContact" onClick={this.goToStep3}>Contact Support</Button>}
        </Box>
    )
  }

  renderStep3 = () => {
    return (
        <Box>

            <Box className="outsiderBox">
                <Box className="userInfoBox" style={{ alignItems : "center"}}>
                    <img src={fileIcon} className="iconCss" />
                    {this.renderCommonHeader()}
                </Box>
                <Box className="statusBox" style={{ alignItems : "flex-start"}}>
                    <Button onClick={this.handleReUploadDocumentRefCall} className="secondaryBtn" data-test-id="changeToStep4">Re-upload License</Button>
                    <input
                        type="file"
                        data-test-id={"reUploadLicenceRef"}
                        onChange={this.handleReUploadDocumentChange}
                        ref={this.reUploadLicenceRef}
                        style={{ display: "none" }}
                    />

                    <Box className={this.handleChipCSS(this.state.verificationStatus)}>
                        {this.state.verificationStatus}
                    </Box>
                </Box>
            </Box>

            {this.renderCommonCards()}

            
        </Box>
    )
  }

  renderStep4 = () => {
    return (
        <Box>
            <Box className="outsiderBox">
                <Box className="userInfoBox" style={{ alignItems : "center"}}>
                    <img src={fileIcon} className="iconCss" />
                    {this.renderCommonHeader()}
                </Box>
                <Box className="statusBox" style={{ alignItems : "flex-start"}}>
                    <Button onClick={this.goToStep5} className="secondaryBtn" data-test-id="checkStatusBtn">Check Status</Button>

                    <Box className={this.handleChipCSS(this.state.verificationStatus)}>
                        {this.state.verificationStatus}
                    </Box>
                </Box>
            </Box>

            {this.renderCommonCards()}

            
        </Box>
    )
  }

  renderStep5 = () => {
    return (
        <Box>
            <Box className="outsiderBox">
                <Box className="userInfoBox" style={{ alignItems : "center"}}>
                    <img src={fileIcon} className="iconCss" />
                    {this.renderCommonHeader()}
                </Box>
                <Box className="statusBox" style={{ alignItems : "flex-start"}}>
                    <Button onClick={this.clearAllFields} className="secondaryBtn" data-test-id="clearBtn">Contatct Support</Button>

                    <Box className={this.handleChipCSS(this.state.verificationStatus)}>
                        {this.state.verificationStatus}
                    </Box>
                </Box>
            </Box>

            {this.renderCommonCards()}

        </Box>
    )
  }

  renderCommonCards  = () => {
    return (
        <Box>
            <Box className="outsiderBox">
                <Box className="userInfoBox" style={{ alignItems: "center" }}>
                    <img src={homeIcon} className="iconCss" />
                    <Typography className="licenceText">Home rental status</Typography>

                </Box>
                <Box className="statusBox">
                    <Box style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        {this.handleIcons()}
                        <Typography className="noteText"> All homes </Typography>
                    </Box>
                </Box>
            </Box>

            <Box className="outsiderBox">
                <Box className="userInfoBox" style={{ alignItems: "center" }}>
                    <img src={carIcon} className="iconCss" />
                    <Typography className="licenceText">Car rental status</Typography>

                </Box>
                <Box className="statusBox" style={{ flexDirection: "column", gap: 5 }}>
                    <Box style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        {this.handleIcons()}
                        <Typography className="noteText"> All cars under $75,000 MSRP </Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        {this.handleIcons()}
                        <Typography className="noteText"> Luxury & exotic vehicles </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
  }

    renderCommonHeader = () => {
        return (
            <Box>
                <Typography className="licenceText">Driver license verification</Typography>
                <Typography className="expireText">Expires 12/2/24</Typography>
            </Box>
        )
    }

  render() {
    // Customizable Area Start
    
    return (
        <div>

            <AppHeader navigation={this.props.navigation} />

            <Wrapper>

                <ProfileHeader />

                <Grid container spacing={3}>
                    <Grid item className="mainGrid">
                        <Box style={{ margin: "30px 0" }} className="mainBoxStyle">
                            <ProfileSidebar activeKey={4} navigation={this.props.navigation} />
                        </Box>
                    </Grid>
                    <Grid item md={9} className="mainBoxSecondary">
                        <Box style={{ margin: "30px 0" }}>
                            <Box className="paddingBox">
                                <Typography className="mainText">Verification & Insurance</Typography>
                                <Typography className="subText">Manage your verification status and insurance here.</Typography>
                            </Box>

                            {this.state.loading ? <Loader loading />
                                :
                                <Box className="commonBox">
                                    <Box className="outsiderBox">
                                        <Box className="userInfoBox">
                                            <img src={this.state.userData.profileImg} className="profileImg" />
                                            <Box>
                                                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                                    <Typography className="userName">{this.state.userData.fullName}</Typography>
                                                    <VerifiedUserIcon style={{ height: 22, width: 22, color: "#3173E1" }} />
                                                </div>
                                                <Typography className="userJoining">Joined in {this.state.userData.joined}</Typography>
                                            </Box>

                                        </Box>
                                        <Box className="statusBox">
                                            <Typography className="noteText"> {this.handleChipNoteText(this.state.verificationStatus)} </Typography>
                                            <Box className={this.handleChipCSS(this.state.verificationStatus)}>
                                                {this.state.verificationStatus}
                                            </Box>
                                        </Box>
                                    </Box>

                                    {this.handleVerificationStep()}

                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Wrapper>

            <Footer />

        </div>
    );
    // Customizable Area End
  }
}

export default ApprovalStatus;

export const Wrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "auto",
    "& .mainBox": {
        "@media(max-width:960px)": {
            display: "flex",
        }
    },
    "& .mainBoxStyle": {
        "@media(max-width:960px)": {
            display: "flex",
            "& .MuiGrid": {
                maxWidth: "100% !important"
            }
        }
    },
    "& .mainBoxSecondary": {
        width: "100% !important"
    },
    "& .mainGrid": {
        "@media(max-width:960px)": {
            width: "100% !important",
            overflowX: "scroll",
        }
    },
    "& .mainText": {
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 600,
        color: "#101828",
        marginBottom: "4px",
        fontSize: "18px"
    },
    "& .paddingBox": {
        padding: "0 30px",
    },
    "& .commonBox" : {
        padding: "20px 30px",
    },
    "& .subText": {
        color: "#475467",
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 400,
        fontSize: "16px"
    },
    "& .outsiderBox" : {
        boxShadow: "0px 4px 5px 0px #0000000D",
        border: "1px solid #DDE0E6",
        borderRadius: 12,
        width: "100%",
        marginBottom: "25px"
    },
    "& .userInfoBox" : {
        display : "flex",
        gap : 20,
        padding: "12px 25px",
    },
    "& .profileImg" : {
        height: 58,
        width: 58,
        borderRadius : "50%"
    },
    "& .userName" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: 600,
        color: '#23395D',
        textTransform: "capitalize"
    },
    "& .userJoining" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#4C6081'
    },
    "& .statusBox" : {
        background : "#EEF5FF",
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 25px",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius : "12px"
    },
    "& .noteText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
        color: '#23395D',
    },
    "& .unVerifiedChip" : {
        background : "#B8CFF0",
        padding: "4px 18px",
        borderRadius: "100px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
        color: '#6492D4',
    },
    "& .progressChip" : {
        padding: "4px 18px",
        background : "#FFB84E",
        fontFamily: 'Plus Jakarta Sans',
        color: '#FFF0DB',
        fontSize: '12px',
        fontWeight: 500,
        borderRadius: "100px",
    },
    "& .verifiedChip" : {
        background : "#139030",
        borderRadius: "100px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        color: '#FFF0DB',
        padding: "4px 18px",
        fontWeight: 500,
    },
    "& .deniedChip" : {
        background : "#CB0A0A",
        padding: "4px 18px",
        borderRadius: "100px",
        color: '#FFF0DB',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
    },
    "& .primaryBtn" : {
        background: "#3173E1",
        padding: "10px 16px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        color: '#FFF0DB',
        fontWeight: 600,
        textTransform: "none",
        borderRadius : "12px",
        margin: "10px 0"
    },
    "& .stepHeaderText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        color: '#23395D',
        fontWeight: 600,
    },
    "& .subStepTxt" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        color: '#475467',
        fontWeight: 400,
        margin: "10px 0"
    },
    "& .drivingLicenseBox" : {
        background: "#EEF5FF",
        borderRadius: "12px",
        padding : "10px 18px",
        display: "flex",
        gap : "10px",
        alignItems: "center",
        margin: "10px 0",
        cursor : "pointer"
    },
    "& .infoStepText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        color: '#686B71',
        fontWeight: 500,
    },
    "& .iconCss" : {
        height : 38,
        width: 38
    },
    "& .licenceText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        color: '#10264B',
        fontWeight: 500,
    },
    "& .expireText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '10px',
        color: '#9E9E9E',
        fontWeight: 500,
    },
    "& .secondaryBtn" : {
        height : 40,
        borderRadius : "12px",
        background: "#3173E1",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        color: '#FFF0DB',
        padding: "10px 16px",
        fontWeight: 600,
        textTransform: "none",
    }
    
})

// Customizable Area End