// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { apiCalling } from '../../../components/src/asset'
import { toast } from "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface CardInfoErrors {
    "card_number": boolean,
    "card_holder_name": boolean,
    "expiry_date": boolean,
    "security_code": boolean,
    "state":boolean,
    "city": boolean,
    "zip_code": boolean,
    "street": boolean,
    "country": boolean
}
interface CardInfo {
    "card_number": string,
    "card_holder_name": string,
    "expiry_date": string,
    "security_code": string,
    "street": string,
    "city": string,
    "state":string,
    "zip_code": string,
    "country": string
}
interface EditCardDataInfo{
  security_code:string,
  card_number:string,
  card_holder_name:string,
  expiry_date:string,
  payment_method_details:{
    billing_details:{
      email: string;
      phone: string;
      address:{
        state: string;
        city: string,
        country:string,
        line1:string,
        line2: string,
        postal_code:string,
      }
    },
    card: {
      exp_month: number,
      exp_year: number,
    }
  }
}

interface S {
  // Customizable Area Start  
  openAddCardModal: boolean,
  cardDetails : CardInfo,
  cardDetailsErrors : CardInfoErrors,
  savedCards : any[],
  loading : boolean,
  editCardData:EditCardDataInfo,
  paymentId: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCardInfoApiCallId : string = ""
  addCardInfoApiCallId : string =""
  defaultCardApiCallId : string = "";
  editCardInfoApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        openAddCardModal : false,
        cardDetails : {
            "card_number": "",
            "card_holder_name": "",
            "expiry_date": "",
            "security_code": "",
            "street": "",
            "city": "",
            "state": "",
            "zip_code": "",
            "country": ""   
        },
        cardDetailsErrors : {
            "card_number": false,
            "card_holder_name": false,
            "expiry_date": false,
            "security_code": false,
            "street": false,
            "city": false,
            "state": false,
            "zip_code": false,
            "country": false
        },
        savedCards: [],
        loading: true,
        editCardData:{
          security_code: "",
          card_number: "",
          card_holder_name: "",
          expiry_date:"",
          payment_method_details: {
            billing_details: {
              email: "",
              phone: "",
              address: {
                city: "",
                country: "",
                line1: "",
                line2: "",
                postal_code: "",
                state:""
              }
            },
            card: {
              exp_month: 0,
              exp_year: 0,
            }
          },
        },
        paymentId: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getCardInfoApiCallId : 
        console.log("popppp22223333", responseJson)
           return this.getCardApiResponse(responseJson)
           
        case this.addCardInfoApiCallId : 
           return this.addCardApiResponse(responseJson)
        case this.defaultCardApiCallId : 
           return this.defaultCardApiResponse(responseJson)
        case this.editCardInfoApiCallId :
            if(responseJson.message){
              this.setState({
                openAddCardModal: false
              })
              this.getCardInfo()
            }
        default :
           break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      this.getCardInfo()
  }
  
  addCardApiResponse = (responseJson : any) => {
    if(responseJson && responseJson.data) {
        this.closeAddCardModal()
        toast.success("Card Saved Successfully")
        this.getCardInfo()
    } else if(responseJson && responseJson.error ) {
        let errMsg = ""
        if(responseJson.error && responseJson.error.card_number) {
           errMsg = responseJson.error.card_number[0]
           toast.error(errMsg)
        } 
        errMsg = responseJson.error.message
        toast.error(errMsg)
    }
     else {
        toast.error('Something went wrong')
    }

  }

  getCardApiResponse = (responseJson : any) => {
    if(responseJson && responseJson.data) {
        const cardArray = responseJson.data.filter((cardValue :any) => cardValue.attributes.payment_method_details !== null)
        this.setState({ savedCards : cardArray, loading: false})
    } else {
        this.setState({ savedCards : [], loading: false})
    }
  }

  defaultCardApiResponse = (responseJson : any) => {
    if(responseJson && responseJson.data) {
        this.getCardInfo()
        toast.success("Default Card Updated Succeffully")
    } else {
        toast.success("Something went wrong!. Please Try again later")
    }
  }

  oepnAddCardModal = () => {
    this.setState({ openAddCardModal : true, editCardData:{
      security_code: "",
      card_number: "",
      card_holder_name: "",
      expiry_date:"",
      payment_method_details: {
        billing_details: {
          email: "",
          phone: "",
          address:{
            city: "",
            country: "",
            line1: "",
            line2: "",
            postal_code: "",
            state:""
          }
        },
        card: {
          exp_month: 0,
          exp_year: 0,
        }
      },
    }})
  }
  oepnAddCardEditModal = (event:React.MouseEvent<HTMLButtonElement>,value:EditCardDataInfo, paymentId:string) => {
    event.stopPropagation()
    this.setState({ openAddCardModal : true, editCardData:value, paymentId: paymentId})
  }

  closeAddCardModal = () => {
    this.setState({ openAddCardModal : false, editCardData:{
      security_code: "",
      card_number: "",
      card_holder_name: "",
      expiry_date:"",
      payment_method_details: {
        billing_details: {
          email: "",
          phone: "",
          address:{
            city: "",
            country: "",
            line1: "",
            line2: "",
            postal_code: "",
            state:""
          }
        },
        card: {
          exp_month: 0,
          exp_year: 0,
        }
      }
    }})
  }

  getCardInfo = () => {
    const header = {
        'token': localStorage.getItem("userToken")
      }

    this.getCardInfoApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.getCardInfoApiEndPoint
    })
  }

  editCardInfo = () => {
    const header = {
      "Content-Type": configJSON.contentTypeJson,
      'token': localStorage.getItem("userToken")
    };

    const httpBody = {
      "card": {
        "card_holder_name": this.state.editCardData.card_holder_name,
        "email": this.state.editCardData.payment_method_details?.billing_details?.email,
        "phone": this.state.editCardData.payment_method_details?.billing_details?.phone,
        "address_line1": this.state.editCardData.payment_method_details?.billing_details?.address.line1,
        "address_line2": this.state.editCardData.payment_method_details?.billing_details?.address.line2,
        "city": this.state.editCardData.payment_method_details?.billing_details?.address.city,
        "state": this.state.editCardData.payment_method_details?.billing_details?.address.state,
        "postal_code": this.state.editCardData.payment_method_details?.billing_details?.address.postal_code,
        "country": this.state.editCardData.payment_method_details?.billing_details?.address.country,
        "exp_month": this.state.editCardData.payment_method_details?.card.exp_month,
        "exp_year": this.state.editCardData.payment_method_details?.card.exp_year
      }
    }

    this.editCardInfoApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: "PUT",
      endPoint: `/bx_block_stripe_integration/payment_methods/update_card?id=${this.state.paymentId}`,
      body: JSON.stringify(httpBody)
    })
  };

  saveCardInfo = () => {

    if(!this.handleAllValidationOnCardSave()) {
        return false;
     }

    const header = {
        "Content-Type": configJSON.contentTypeJson,
        'token': localStorage.getItem("userToken")
    }


      const httpBody = {
        "card_number": this.state.cardDetails.card_number,
        "card_holder_name": this.state.cardDetails.card_holder_name,
        "expiry_date": this.formatToMonthYear(this.state.cardDetails.expiry_date),
        "security_code": this.state.cardDetails.security_code,
        "address": {
          "street": this.state.cardDetails.street,
          "city": this.state.cardDetails.city,
          "state": this.state.cardDetails.state,
          "zip_code": this.state.cardDetails.zip_code,
          "country": this.state.cardDetails.country
        }
      }

    this.addCardInfoApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypePost,
        endPoint: configJSON.saveCardInfoApiEndPoint,
        body: JSON.stringify(httpBody)
    })
  }

  defaultCardInfo = (cardId : string) => {

    this.setState({ loading : true})
    const header = {
        "Content-Type": configJSON.contentTypeJson,
        'token': localStorage.getItem("userToken")
      }

    const httpBody = {
        "card_id" : cardId
    }

    this.defaultCardApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypePost,
        endPoint: configJSON.defaultCardApiEndPoint,
        body: JSON.stringify(httpBody)
    })
  }

  handleChnageOfAddCard = (event : React.ChangeEvent<HTMLInputElement>) => {
    const formValue = event.target.value
    const formName = event.target.name

    if(formValue.trim() === "") {
        this.setState({ cardDetailsErrors: { ...this.state.cardDetailsErrors, [formName] : true }})
    } else {
        this.setState({ cardDetailsErrors: { ...this.state.cardDetailsErrors, [formName] : false }})
    }

    this.setState({ cardDetails: { ...this.state.cardDetails, [formName] : formValue }})
    
  }

  handleAllValidationOnCardSave = () => {
    let checkValidation = {
      "card_number": false,
      "card_holder_name": false,
      "expiry_date": false,
      "security_code": false,
      "street": false,
      "city": false,
      "state": false,
      "zip_code": false,
      "country": false
      };
  
      if (this.state.cardDetails.card_holder_name === "") {
        checkValidation.card_holder_name = true;
      } if (this.state.cardDetails.card_number === "" || this.state.cardDetails.card_number.length !== 16) {
        checkValidation.card_number = true;
      } if (this.state.cardDetails.expiry_date === "") {
        checkValidation.expiry_date = true;
      } if (this.state.cardDetails.security_code === "" || this.state.cardDetails.security_code.length < 3) {
        checkValidation.security_code = true;
      } if (this.state.cardDetails.street === "") {
        checkValidation.street = true;
      } if (this.state.cardDetails.city === "") {
        checkValidation.city = true;
      } if (this.state.cardDetails.state === "") {
        checkValidation.state = true;
      } if (this.state.cardDetails.zip_code === "") {
        checkValidation.zip_code = true;
      } if (this.state.cardDetails.country === "") {
        checkValidation.country = true;
      }
  
      this.setState({ cardDetailsErrors : checkValidation})
  
      return Object.values(checkValidation).every((value) => value === false);
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (
      !(
        (event.key >= '0' && event.key <= '9') ||
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'Tab'
      )
    ) {
      event.preventDefault();
    }
  }

    handleErrorFields = (checkKey: boolean, errMsg: string) => {
        return checkKey && <div className="textLabel" style={{ color: "red", marginBottom: "7px" }}>{errMsg}</div>
    }

    handleFormattedDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const currentDate = String(today.getDate()).padStart(2, '0');
        const formattedToday = `${yyyy}-${month}-${currentDate}`;

        return formattedToday
    }

    formatToMonthYear(dateString: string) {
        const date = new Date(dateString);
    
        const month = date.getMonth() + 1; 
        const year = date.getFullYear();
        
        const formattedDate = `${month.toString().padStart(2, '0')}/${year}`;
        
        return formattedDate;
    }

    handleCancelBtn = () => {
        const initialCardValues = {
            card_number: "",
            card_holder_name: "",
            expiry_date: "",
            security_code: "",
            street: "",
            city: "",
            state: "",
            zip_code: "",
            country: ""
        };

        const initialCardErrors = {
            card_number: false,
            card_holder_name: false,
            expiry_date: false,
            security_code: false,
            street: false,
            city: false,
            state: false,
            zip_code: false,
            country: false
        };

        this.setState({ cardDetails : initialCardValues, cardDetailsErrors: initialCardErrors, openAddCardModal: false})
    }


  // Customizable Area End
}

// Customizable Area End