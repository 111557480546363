// Customizable Area Start
import React from "react";
import { 
  Box, 
  styled, 
  Typography, 
  Tabs, 
  Tab, 
  Card, 
  Grid, 
  Divider, 
  Table, 
  TableCell, 
  TableBody, 
  TableContainer, 
  TableHead, 
  Paper, 
  TableRow,
  TableFooter,
  Tooltip, 
  OutlinedInput,
  Select,
  MenuItem
} from "@material-ui/core"
import AppHeader from "../../notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web";
import { calenderIcon, arrowLeftIcon, arrowRightIcon, dropDownIcon, searchIcon, filterIcon, sortIcon } from "./assets";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Pagination from '@material-ui/lab/Pagination';


import ReservationController, {
    Props,
  } from "./ReservationController";


class Reservation extends ReservationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  handleErrorDiv = (errorMsg : string) => {
    return (
    <Box style={{ margin: "20px 0", textAlign: "center" }}>
      <Typography>{errorMsg}</Typography>
    </Box>
    )
  }

  renderUpcomingSection = () => {
    return (
      <Box>
        {this.state.reservationData.length > 0 ?
          <Box style={{ margin: "20px 0", cursor: "pointer" }}>
            <Grid container spacing={2}>
              {this.state.reservationData.map((item: any) => {
                const { imgUrl, checkInTime, checkOutTime, catalogueName, address, pinCode, refundedText, refundedDate, priceDue } = this.upcomingReservationData(item)
                return (
                  <Grid onClick={() => this.navigateToInfoPage(item.id, item.attributes.catalogue_type)} data-test-id="navToInfo" item md={6} key={item.id}>
                    <Card style={{ padding: 16 }}>
                      <Grid container spacing={2}>
                        <Grid item md={4}>
                          <Box>
                            <img style={{ height: 150, width: 180, borderRadius: "12px" }} src={imgUrl} />
                          </Box>
                        </Grid>
                        <Grid item md={8}>
                          <Box>
                            <Typography className="catalougeTitleText" noWrap>{catalogueName}</Typography>
                            <Typography className="addressText" noWrap>{address} </Typography>
                            <Typography className="addressText">{pinCode}</Typography>
                            <Box style={{ display: "flex", alignItems: "center", gap: "8px", margin: "18px 0 8px 0" }}>
                              <img src={calenderIcon} alt="icon" />
                              <Typography className="checkInOutText"> Check-in : <span className="chekedInOutValue">{checkInTime}</span></Typography>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center", gap: "8px", margin: "0 0 18px 0" }}>
                              <img src={calenderIcon} alt="icon" />
                              <Typography className="checkInOutText"> Check-out : <span className="chekedInOutValue">{checkOutTime}</span></Typography>
                            </Box>
                            <Divider />
                            <Box style={{ display: "flex", justifyContent: "space-between", marginTop: 12 }}>
                              <Box>
                                <Typography className="priceText">Total Price Due</Typography>
                                <Typography className="refundText">${refundedText} by <span className="colorText"> {refundedDate}</span></Typography>
                              </Box>
                              <Typography className="priceText">${priceDue}</Typography>
                            </Box>

                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )
              }



              )}
            </Grid>
          </Box>
          :
          <>
            {this.handleErrorDiv("No upcoming reservations are available")}
          </>
        }
      </Box>
    )
  }

  renderHistorySection = () => {
    return (
      <Box>
        {this.state.reservationHistoryData.length > 0 ?
          <Box style={{ margin: "20px 0" }}>

            <Box style={{ margin: "20px 0", display: "flex", justifyContent: "space-between" }}>
              <Box>
                <OutlinedInput
                  className="searchBtn"
                  placeholder="Search here"
                  startAdornment={<img src={searchIcon} />}
                />
              </Box>

              <Box style={{ display: "flex", gap: 10 }}>
                <button className="sortBtn">
                  <img src={filterIcon} />Filter
                </button>
                <button className="sortBtn">
                  <img src={sortIcon} />
                   
                  <Select
                    value={this.state.sortingType}
                    onChange={this.handleSorting}
                    disableUnderline
                    data-test-id="sorting"
                    className="paginationSection"
                  >
                    <MenuItem value={"latest"}>Latest</MenuItem>
                    <MenuItem value={"oldest"}>Oldest</MenuItem>
                  </Select>
                </button>
              </Box>
            </Box>

            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="tableHeaderRow">
                    <TableCell className="tableHeaderText">Brand/Make</TableCell>
                    <TableCell className="tableHeaderText">Reservation Date (From - To)</TableCell>
                    <TableCell className="tableHeaderText">Action</TableCell>
                  </TableRow>
                </TableHead>
                  {this.state.reservationHistoryData.map((item, index) =>
                    <TableBody key={index}>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography className="monthText">{item.monthName}</Typography>
                        </TableCell>
                      </TableRow>
                      {item.historyData.map((reservations: any) => {
                        const { catalogueName, imgUrl, address, checkInTime, checkOutTime } = this.reservationHistoryData(reservations)
                        return (
                          <TableRow key={reservations.id}>
                            <TableCell component="th" scope="row">
                              <Box style={{ display: "flex", gap: 10, cursor : "pointer" }} data-test-id="postClick" onClick={() => this.navigateToPostTripPage(reservations.id)}>
                                <Box>
                                  <img
                                    style={{ height: 48, width: 48, borderRadius: "100%" }}
                                    src={imgUrl} />
                                </Box>
                                <Box>
                                  <Typography className="historyDate">{catalogueName}</Typography>
                                  <Tooltip title={address}>
                                    <Typography className="historyHeaderText" style={{ maxWidth: 200 }} noWrap>{address}</Typography>
                                  </Tooltip>
                                </Box>
                              </Box>

                            </TableCell>
                            <TableCell>
                              <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                <Box style={{ padding: 12 }}>
                                  <Typography className="historyDate">{checkInTime}</Typography>
                                  <Tooltip title={address}>
                                    <Typography className="historyHeaderText" style={{ maxWidth: 200 }} noWrap>{address}</Typography>
                                  </Tooltip>
                                </Box>
                                <Box style={{ padding: 12 }}>-</Box>
                                <Box style={{ padding: 12 }}>
                                  <Typography className="historyDate">{checkOutTime}</Typography>
                                  <Tooltip title={address}>
                                    <Typography className="historyHeaderText" style={{ maxWidth: 200 }} noWrap>{address}</Typography>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box style={{ display: "flex", alignItems: "center", gap: 15, cursor: "pointer" }} data-test-id="navToReview" onClick={() => this.navigateToReviewPage(reservations.id)}>
                                <Typography className="actionText"><span className="colorText"> Write a Review  </span></Typography>
                                <ArrowForwardIosIcon className="colorText" style={{ height: 20, width: 20 }} />
                              </Box>
                              <Box style={{ display: "flex", alignItems: "center", gap: 15 }}>

                                <Typography className="actionText">Report an Issue </Typography>
                                <ArrowForwardIosIcon style={{ height: 20, width: 20 }} />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      })}

                    </TableBody>
                  )}
                <TableFooter>
                  <TableRow>
                    <TableCell style={{ margin: 16 }} colSpan={2}>
                      <Box style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                        <CustomPaginationWrapper
                          data-test-id="pagination"
                          count={this.state.totalPages}
                          page={this.state.currentPage}
                          onChange={this.handlePaginationChange}
                          siblingCount={0}
                          boundaryCount={3}
                        />
                        <Box style={{ display: 'flex', alignItems: 'center', gap: '13px', fontSize: '14px', fontWeight: 700, color: '#475467', cursor: "pointer" }}>
                          Show:
                          <Select
                            value={this.state.perPage}
                            onChange={this.perPageChange}
                            disableUnderline
                            data-test-id="pageChange"
                            className="paginationSection"
                          >
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                          </Select>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{ margin: 16 }} colSpan={1}>
                      <Box style={{ display: "flex" }} >
                        {this.state.currentPage > 1 &&
                          <Box data-test-id="previousIcon" onClick={this.onPreviousPage} style={{ display: "flex", gap: 8, cursor: "pointer", alignItems: 'center' }}>
                            <img style={{ width: "20px" }} src={arrowLeftIcon}></img>
                            <Typography >Previous</Typography>
                          </Box>}
                        {this.state.currentPage < this.state.totalPages &&
                          <Box data-test-id="nextIcon" onClick={this.onNextPage} style={{ display: "flex", gap: 8, alignItems: 'center', marginLeft: "14px", cursor: "pointer" }}>
                            <Typography >Next</Typography>
                            <img style={{ width: "20px" }} src={arrowRightIcon}></img>
                          </Box>}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box> :
          <Box>
            {this.handleErrorDiv("No reservation history available")}
          </Box>

        }
      </Box>
    )
  }

  render() {
    // Customizable Area Start
    return (
      <div>
        <AppHeader activeLink="Reservation" navigation={this.props.navigation} />
         
        <ReservationWrapper>
          <Typography className="headerText">My Reservations</Typography>

          <Box style={{ marginTop: "30px" }}>
            <Tabs
              className="mainTabs"
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              data-test-id="tabs"
            >
              <Tab label="Upcoming" className="tabText"></Tab>
              <Tab label="History" className="tabText"></Tab>
            </Tabs>
          </Box>

          {this.state.loading ? <Loader loading /> : 
          <div>
              {this.state.tabValue === 0 && this.renderUpcomingSection()}
              {this.state.tabValue === 1 && this.renderHistorySection()}
          </div>
          }
            
        </ReservationWrapper>
         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default Reservation;

const ReservationWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .headerText": {
        textAlign: 'left',
        fontSize: '30px',
        fontWeight: 600,
        fontFamily: 'Plus Jakarta Sans',
        color:"#23395D",
    },
    "& .mainTabs" : {
      borderBottom: "1px solid #E2E8F0"
    },
    "& .mainTabs .Mui-selected" :{
        color : "#3173E1",
        borderBottom: "none"
    },
    "& .mainTabs .MuiTabs-indicator" : {
        backgroundColor : "#3173E1"
    },
    "& .tabText" : {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textTransform: "none",
        color: "#475467"
    },
    "& .catalougeTitleText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
        textAlign: 'left',
        color: '#23395D'      
    },
    "& .addressText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'left',
        color: '#475467' 
    }, 
    "& .checkInOutText": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 400,
      textAlign: 'left',
      color: '#667085',
      height: 20
    },
    "& .chekedInOutValue": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'left',
      color: '#344054'
    },
    "& .priceText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 600,
      textAlign: 'left',
      color: "#344054",
      margin: "5px 0"
    },
    "& .refundText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'left',
      color: "#667085"
    },
    "& .colorText" : {
      color: "#3173E1"
    },
    "& .historyHeaderText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      textAlign: 'left',
      color: '#667085'
    }, 
    "& .historyDate" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      textAlign: 'left',
      color: '#23395D'
    }, 
    "& .actionText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      textAlign: 'left',
      padding: "6px 6px 6px 12px",
      minWidth: 110,
      color: ' #475467'
    },
    "& .tableHeaderRow" : {
      background: "#EAECF0"
    },
    "& .tableHeaderText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '20px',
      color: '#475467'
    },
    "& .sortBtn" : {
      border: '1px solid #D0D5DD', 
      borderRadius: 30, 
      padding: '8px 16px', 
      background: 'white', 
      fontSize: 14, 
      fontWeight: 600, 
      color: '#475569', 
      display: 'flex', 
      alignItems: 'center', 
      gap: 8 ,
      cursor:"pointer"
    },
    "& .searchBtn" : {
      height: 40,
      width: 400,
      borderRadius: "30px",
      padding: "10px 16px",
      color: "#475467",
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: 600
    },
    "& .searchBtn .MuiOutlinedInput-input" : {
      padding : 10,
    },
    "& .paginationSection" : {
      color: '#475467',
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: 600,
      fontSize: '14px',
    },
    "& .monthText": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 600,
      color: '#23395D'
    }
})

const CustomPaginationWrapper = styled(Pagination)({
  '& .MuiPagination-ul li:first-child': {
    display: 'none',
  },
  '& .MuiPagination-ul li:last-child': {
    display: 'none',
  },
  '@media(max-width: 767px)': {
    '& .MuiPaginationItem-root': {
      minWidth: 25, height: 25, margin: 0
    }
  }
})

// Customizable Area End