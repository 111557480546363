// Customizable Area Start
import React from "react";

import PostTripCommonSidebar from "../../../components/src/PostTripCommonSidebar.web"
import {
    Box,
    styled,
    Typography,
    Tooltip,
    Button,
    TextareaAutosize
} from "@material-ui/core"
import Rating from '@material-ui/lab/Rating';

import { attachIcon, copyIcon, fbIcon, instaIcon, linkedInIcon, otherIcon, twitterIcon } from "./assets"; 
import StarIcon from '@material-ui/icons/Star';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


import ReviewScreenController, {
    Props,
  } from "./ReviewScreenController";


class ReviewScreen extends ReviewScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderReferSection = () => {
    return (
        <Box>
            <Typography className="reviewHeader">Refer and Earn</Typography>
            <Typography className="reviewSubHeader">Loved your stay? Share your experience on social media or refer friends to earn travel credits.</Typography>

            <Box className="linkBox">
                <img src={attachIcon} alt="attachIcon" style={{ marginLeft : 15}}/>
                <Typography className="linkText">{this.state.referLink}</Typography>
                <Tooltip title={this.state.tooltipText} arrow>
                    <img src={copyIcon} alt="copyIcon" style={{ cursor : "pointer"}} data-test-id="copyIcon" onClick={this.copyUrl}/>
                </Tooltip>
            </Box>

            <Box style={{ textAlign : "center", width : 400, margin : "20px 0"}}>
                <Typography className="shareText"> Or share via </Typography>
            </Box>

            <Box style={{ display : "flex", gap: 20}}>
                <img src={fbIcon} alt="fbIcon" />
                <img src={linkedInIcon} alt="linkedInIcon" />
                <img src={twitterIcon} alt="twitterIcon" />
                <img src={instaIcon} alt="instaIcon" />
                <img src={otherIcon} alt="otherIcon" />
            </Box>

            

            <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                <Button data-test-id="backBtn" onClick={this.navToReservationPage} className="backButtonReview">Skip</Button>
                <Button data-test-id="nextBtn" onClick={this.navToReservationPage} className="continueButtonReview">Finish</Button>
            </Box>
        </Box>
    )
  }

  renderReviewSection = () => {
    return (
        <Box>
           <Typography className="reviewHeader">Leave a Review</Typography>
           <Typography className="reviewSubHeader">Share your experience! Your feedback helps our community grow and ensures a better stay for future guests.</Typography>

           <Box style={{ margin: "20px 0" }}>
             <Typography className="ratingHeader">Select Rating</Typography>
             <Box className="ratingBox">
                <Rating 
                   precision={0.5} 
                   size="large"
                   emptyIcon={<StarIcon className="emptyIcon" />}
                   icon={<StarIcon className="fillIcon" />}
                   value={this.state.ratingValue}
                   data-test-id="rating"
                   onChange={this.handleRatingValue}
                />
             </Box>
           </Box>

            <Box style={{ margin: "20px 0" }}>
                <Box style={{ display: "flex", gap: 8, alignItems: "center" }}>
                    <Typography className="ratingHeader">Description </Typography>
                    <HelpOutlineIcon style={{ height: 20, width: 20}} />
                </Box>
                <TextareaAutosize
                    placeholder="Tell guests what makes your car unique and why they’ll love driving it. New listings with descriptions of least 100 words are up to 3x more likely to get booked."
                    className="ratingDescription"
                    value={this.state.ratingDescription}
                    onChange={this.handleChangeForRatingDescription}
                    data-test-id="ratingDescription"
                />
            </Box>

           <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                <Button data-test-id="backBtn" onClick={this.navToReservationPage} className="backButtonReview">Skip</Button>
                <Button data-test-id="nextBtn" onClick={this.saveReview} className="continueButtonReview">Continue</Button>
            </Box>
        </Box>
    )
  }

  render() {
    // Customizable Area Start
    
    return (
      <div>
         <PostTripCommonSidebar navigation={this.props.navigation} id="reviewPage" pageTitle="ReviewScreen">
            <ReviewWrap>
                {this.state.renderSection === this.allSection.reviewSection ? this.renderReviewSection() : this.renderReferSection()}
            </ReviewWrap>
         </PostTripCommonSidebar>
      </div>
    );
    // Customizable Area End
  }
}

export default ReviewScreen;

const ReviewWrap = styled(Box)({
    "& .reviewHeader": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D'
    },
    "& .reviewSubHeader": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#475467'
    },
    "& .linkBox" : {
        border: "1px solid #E7E7E7",
        height: 56,
        width : 400,
        display : "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0",
        boxShadow: "0px 4px 5px 0px #0000000D",
        borderRadius : "12px"
    },
    "& .linkText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#23395D'
    },
    "& .shareText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#7D8086'
    },
    "& .continueButtonReview" : {
        width: '150px',
        height: '48px',
        gap: '8px',
        backgroundColor: '#3173E1',
        textTransform: "none",
        borderRadius: '12px',
        color: 'white',
        margin: "15px 0",
        padding: '14px 16px',
        fontWeight: "bold",
      },
      "& .backButtonReview" : {
        borderRadius: '12px',
        gap: '8px',
        color: '#475467',
        height: '48px',
        width: '115px',
        border: "1px solid #D0D5DD",
        fontWeight: "bold",
        backgroundColor: 'white',
        padding: '14px 16px',
        margin: "15px 0",
        textTransform: "none"
      },
      "& .ratingHeader" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#23395D'
      },
      "& .ratingBox" : {
        border: "1px solid #E7E7E7",
        borderRadius : "12px",
        boxShadow:" 0px 4px 5px 0px #0000000D",
        padding : "15px 30px",
        width: "40%"
      },
      "& .fillIcon" : {
        color : "#3173E1",
        height:43,
        width : 43
      },
      "& .emptyIcon" : {
        color : "#C4CFE2",
        height:43,
        width : 43
      },
      "& .ratingBox .MuiRating-root" :{
        gap : 25
      },
    "& .ratingDescription": {
        padding: "20px",
        height: '185px !important',
        width: '669px',
        gap: '8px',
        borderRadius: '12px',
        border: "1px solid #D0D5DD"
    }
})

// Customizable Area End